.gallery {
  contain: none;
  overflow: hidden;
  & .gallery-main .swiper-container {
    width: 100%;
    height: auto;
  }

  & .gallery-main .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 465px;
  }
}

.gallery-main-item,
.gallery-lightbox-item {
  & img,
  & iframe {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.gallery-main {
  contain: content;
  padding: 50px 0 100px;
  margin: -50px 0 -100px;
}

.gallery-main-item {
  cursor: zoom-in;
  transition: transform 0.2s ease-in-out;
  contain: layout;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    transform: scale(1.02);
  }

  & img {
    width: 100%;
    object-fit: cover;
  }
}

.gallery-main-item-inner {
  width: 100%;
  height: 100%;
}

.gallery-main-item-video {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: var(--color-blue-main); */
    opacity: 0.75;
    z-index: 2;
  }
}

.gallery-main-item-small {
  width: 328px !important;
  height: 216px !important;
}

.gallery-main-item-big {
  width: 684px !important;
  height: 464px !important;

  @media (--lt-1000) {
    width: 328px !important;
    height: 216px !important;
  }
}

.gallery-header {
  margin-bottom: 40px;

  & h1 {
    margin: 0;
  }

  /* Common controls classes are in separate file common for all sliders in dev/front/css/plugins/swiper.css */
  & .slider-controls {
    margin-left: auto;
    padding-left: 2em;
  }
}

.gallery-lightbox {
  display: none;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  contain: strict;

  &.is-active {
    display: block;
  }

  & .swiper-container {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
  }
}

.gallery-lightbox-item {
  background-color: rgba(54, 56, 58, 0.85);
  cursor: zoom-out;
  contain: content;

  & img,
  & iframe {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
  }

  & img {
    max-width: calc(100% - 160px);
    max-height: calc(100% - 80px);

    @media (--lt-1100) {
      max-width: calc(100% - 80px);
    }
  }
}

.slider-lightbox-controls-left,
.slider-lightbox-controls-right {
  position: absolute;
  top: 50%;
  z-index: 3;
  width: 10%;
  height: 10%;
  max-width: 40px;
  max-height: 40px;
}

.slider-lightbox-controls-left {
  left: 40px;
  transform: translate(-50%, -50%);

  @media (--lt-1100) {
    left: 0;
    transform: translate(0, -50%);
  }
}

.slider-lightbox-controls-right {
  right: 40px;
  transform: translate(50%, -50%);

  @media (--lt-1100) {
    right: 0;
    transform: translate(0, -50%);
  }
}

.slider-lightbox-close {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 999;

  @media (--lt-1100) {
    top: 0;
    right: 0;
  }
}
