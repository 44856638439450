.section-text-image {
  position: relative;
  padding: 90px 0;
  contain: content;

  @media (--lt-1000) {
    padding: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(50% - 20px);
    background-color: var(--color-white);

    @media (--lt-1200) {
      display: none;
    }
  }

  & .container {
    @apply max1200:min-h-fit min-h-[540px] flex max1200:flex-col flex-row flex-wrap;
    position: relative;
    align-content: flex-start;

    @media (--lt-480) {
      min-height: 0;
    }
  }

  &.section-text-left {
    &:before {
      left: 0;
    }

    & .section-part-image {
      right: 0;
    }
  }

  &.section-text-right {
    &:before {
      right: 0;
    }

    & .section-part-image {
      left: 0;
    }

    & .container {
      flex-direction: row-reverse;
    }
  }
}

.typo {
  padding-top: 50px !important;
  width: 80%;
}

.section-part-text {
  width: calc(50% * 0.85 - 100px);

  @media (--lt-1500) {
    width: calc(50% * 0.85 - 56px);
    padding: 0 56px;
  }

  @media (--lt-1200) {
    width: 100%;
    padding: 40px 32px;
    max-width: 805px;
    margin: 0 auto;
  }
}

.section-part-text-title {
  margin-bottom: 40px;
}

.section-part-text-label {
  margin-bottom: 40px;
  display: block;
}

.section-part-image {
  position: absolute;
  opacity: 0;
  margin-bottom: auto;
  width: calc(50% * 1.15);

  @media (--lt-1500) {
    width: calc(50% * 1.15 - 56px);
  }

  @media (--lt-1200) {
    order: 1;
    width: auto;
    max-width: 100%;
    top: 0;
    position: static;
    transform: translate(0, 0);
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.section-part-video {
    @media (--lt-1200) {
      width: 100%;
    }
  }
}

.reverse {
  @media (--lt-1200) {
    & .section-part-image {
      @apply order-2;
    }
    & .section-part-text {
      @apply order-1;
      padding: 0 0 40px;
    }
  }
}

.section-part-image--video {
  width: 100%;
  margin-left: auto;

  @media (--lt-1200) {
    position: relative;
    padding-bottom: 52.25%; /* 16:9 */
    height: 0;
  }
}

.section-part-text-link {
  margin-top: 40px;
}
