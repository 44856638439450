#cc_div #cm {
  visibility: hidden !important;
}

.cookie-consent {
  right: 30px;
  bottom: 30px;
  max-width: 300px;
  padding: 20px;
  z-index: 200;
  font-size: 14px;
  text-align: center;
  position: fixed;

  & > *:first-child {
    margin-top: 0;
  }

  @media (--lt-1000) {
    right: 0;
    bottom: 0;
    padding: 15px;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
  }
}

.cookie-consent-button {
  margin-top: 16px;
}