body.page-article-list {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

.article-list-grid {
  padding-bottom: 100px;
  grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));

  @media (--lt-1000) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-640) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.article-list-pagination {
  align-items: center;
  justify-content: center;
}

.article-list-pagination-current {
  padding: 0 10px;
}

.article-list-pagination-first {
  margin-right: 10px;
}

.article-list-pagination-last {
  margin-left: 10px;
}
