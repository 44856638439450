body.page-artist {
  padding-top: 203px;

  @media (--lt-1200) {
    padding-top: 45px;
  }

  .section-text-image {
    > .container {
      @media (--lt-1200) {
        flex-direction: column-reverse;
        background-color: transparent;
        padding-bottom: 0;
      }
    }

    .section-part-image {
      box-shadow: none;

      @media (--lt-1200) {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 64px;
        box-shadow: none;
      }

      @media (--lt-700) {
        padding-bottom: 0;
      }
    }

    .section-part-text {
      @media (--lt-1200) {
        text-align: center;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: -110px;
          width: 100%;
          height: calc(100% + 110px);
          background-color: var(--color-white);
          z-index: -1;
        }
      }

      h3 {
        font-size: 18px;

        @media (--lt-1200) {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
    
    .section-part-text-title {
      margin-bottom: 32px;
      margin-top: 146px;

      @media (--lt-1200) {
        margin-top: 0;  
      }
      
      @media (--lt-480) {
        font-size: 40px;
      }
    }

    .section-part-text-label {
      @media (--lt-1200) {
        display: none;
      }
    }
  }

  .section-side {
    align-items: center;
    padding-bottom: 0;

    @media (--lt-1200) {
      padding-top: 0;
      flex-direction: column-reverse;
    }

    h2 {
      @media (--lt-1200) {
        font-size: 40px;
      }
    }

    .container--right {
      @media (--lt-1200) {
        padding-top: 64px;
        padding-bottom: 64px;
      }

      p {
        @media (--lt-1200) {
          font-size: 15px;
        }
      }
    }

    .container-image {
      display: flex;
      justify-content: flex-end;

      @media (--lt-1200) {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 64px;
      }

      @media (--lt-640) {
        margin-top: 0;

      }

      img {
        right: auto;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }
}