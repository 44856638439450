/* COLORS */
.m-bg-blue,
.m-bg-blue-main {
  background-color: var(--color-blue-main);
}

.m-bg-blue-ultralight {
  background-color: var(--color-blue-ultralight);
}

.m-bg-gray-1 {
  background-color: var(--color-gray-1);
}

.m-bg-gray-2 {
  background-color: var(--color-gray-2);
}

.m-bg-white {
  background-color: var(--color-white);
}

/* BOX SHADOW */
.m-box-shadow {
  box-shadow: 0 20px 80px -20px var(--color-gray-shadow);
}

.m-box-shadow-after {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    box-shadow: 0 20px 80px 0 var(--color-gray-shadow);
    transition: all 0.25s ease-in-out;

    @media (--lt-1000) {
      transition-duration: 0s;
    }
  }

  &:hover {
    &:after {
      width: 100%;
      height: 100%;
    }
  }
}
