body.page-about-career {
  & .page-header.headroom--top {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-white);
    }
  }

  & .page-header.headroom--top.is-active {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-black);
    }
  }
}

#about-career-intro {
  padding-top: 80px;
  position: relative;
}

.about-career-text-container {
  margin: auto;
  position: relative;
  z-index: 2;

  @media (--lt-1000) {
    margin-bottom: 160px;
  }
}

.about-career-text-container-inner {
  max-width: 500px;
}

.about-career-intro-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.about-career-intro-jobs {
  position: relative;
  z-index: 2;
  margin-top: auto;
  flex-wrap: nowrap;

  @media (--lt-1000) {
    flex-wrap: wrap;
  }

  @media (--lt-480) {
    padding: 0;
  }
}

.about-career-intro-jobs-item {
  padding: 50px 55px;
  justify-content: center;
  flex: 1;
  background-color: var(--color-white);
  text-align: left;
  border-right: 1px solid var(--color-gray-2);

  @media (--lt-1400) {
    padding: 35px 55px;
  }

  @media (--lt-1000) {
    padding: 25px;
    flex-basis: 50%;
    border-bottom: 1px solid var(--color-gray-2);
  }

  @media (--lt-640) {
    flex-basis: 100%;
  }

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    flex-grow: 0;
    min-width: 200px;
    flex-basis: 240px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    cursor: pointer;
    align-items: center;
    text-align: center;

    @media (--lt-1000) {
      flex-basis: 50%;
    }

    @media (--lt-640) {
      flex-basis: 100%;
    }

    & img {
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
      transition-property: transform;
      margin-bottom: 24px;
    }
    &:hover {
      & img {
        transform: scale(1.15);
      }
    }
  }
}

.about-career-intro-jobs-item-title {
  margin-bottom: 12px;
}

#about-career-motto {
  padding-top: 160px;

  @media (--lt-640) {
    padding-top: 80px;
  }
}

.about-career-motto-image {
  width: 770px;
  margin-bottom: 32px;
}

#about-career-why-accolade {
  padding-bottom: 160px;

  @media (--lt-1000) {
    padding-bottom: 80px;
  }
}

#about-career-benefits {
  padding-bottom: 160px;

  @media (--lt-1000) {
    padding-bottom: 80px;
  }
}

.about-career-benefits-grid {
  @media (--lt-1300) {
    grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));
  }

  @media (--lt-1000) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-560) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.about-career-benefits-grid-item {
  perspective: 1000px;

  &:hover {
    & .about-career-benefits-grid-item-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.about-career-benefits-grid-item-card {
  transform-style: preserve-3d;
  height: 450px;

  @media (--lt-1000) {
    height: 400px;
  }

  @media (--lt-560) {
    height: 350px;
  }
}

.about-career-benefits-grid-item-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.25s linear;
  transform-style: preserve-3d;
}

.about-career-benefits-grid-item-card-front,
.about-career-benefits-grid-item-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.about-career-benefits-grid-item-card-back {
  transform: rotateY(180deg);
  padding: 40px;
}

.about-career-benefits-grid-item-card-back-icon {
  margin: 0 auto 24px;
}

.about-career-benefits-grid-item-card-back-text {
  margin-top: auto;
}

.about-career-benefits-grid-item-card-front-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-career-benefits-grid-item-title {
  margin-top: 18px;
}

#about-career-video {
  padding-top: 160px;

  @media (--lt-640) {
    padding-top: 80px;
  }
}

#about-career-video .youtube-video-wrapper * {
  max-height: 500px;
}

#about-career-search {
  padding-top: 160px;

  @media (--lt-1000) {
    padding-top: 80px;
  }
}

#about-career-gallery {
  padding-bottom: 100px;
}

#about-career-positions {
  padding: 160px 0;

  @media (--lt-1000) {
    padding: 80px 0;
  }

  & h1 {
    margin-bottom: 50px;
  }

  & > .container {
    @media (--lt-640) {
      padding: 0 16px;
    }
  }
}

.position-filter {
  grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  max-width: 440px;
  margin: 0 auto 80px;
}

.position-filter-button {
  &.is-active {
    background-color: var(--color-white);
    color: var(--color-blue-main);
  }
}

.position-list-item {
  contain: content;
  &.is-active {
    & .btn-square-arrow-down:after {
      transform: rotate(270deg);
    }
  }

  &.is-hidden {
    display: none;
  }
}

.position-list-item + .position-list-item {
  margin-top: 8px;
}

.position-list-item-header {
  padding: 50px 120px;
  cursor: pointer;
  gap: 32px;
  flex-wrap: nowrap;

  @media (--lt-1000) {
    padding: 50px 60px;
  }

  @media (--lt-640) {
    padding: 30px;
    flex-wrap: wrap;
  }

  & .btn-square-arrow-down {
    position: relative;
    top: 6px;
  }
}

.position-list-item-header-title {
  margin: 0;

  @media (--lt-640) {
    /*flex-basis: calc(100% - 24px - 32px);*/
    font-size: 15px;
  }
}

.position-list-item-header-info {
  text-align: right;
  margin-left: auto;

  @media (--lt-640) {
    width: 100%;
    text-align: left;
    margin-left: 56px;
  }
}

.position-list-item-body {
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  contain: content;

  & ul {
    margin: 1em;
  }

  & li {
    background-image: url("images/list_bullet_blue.svg");
    background-position: left 9px;
  }
}

.position-list-item-body-inner {
  padding: 0 120px 50px;

  @media (--lt-1000) {
    padding: 0 50px 50px;
  }

  @media (--lt-640) {
    padding: 0 30px 50px;
  }
}

.position-list-item-form-button {
  margin-top: 32px;
}

#about-career-form {
  padding: 160px 0 100px;

  & input[disabled] {
    border: 1px solid var(--color-blue-darker);
    color: var(--color-blue-main);
  }

  & .position-field-container {
    grid-column-start: 1;
    grid-column-end: 3;
    position: relative;
    height: 47px;

    & input[name="position"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    & #positionResetButton {
      display: block;
      position: absolute;
      top: 16px;
      right: 18px;

      & svg {
        width: 18px;
        height: 18px;
        stroke: var(--color-blue-main);

        &:hover {
          stroke: #fff;
        }
      }
    }
  }

  @media (--lt-1000) {
    padding: 80px 0;
  }

  & .container {
    padding: 80px 120px;

    @media (--lt-1000) {
      padding: 80px 60px;
    }

    @media (--lt-640) {
      padding: 40px 32px;
    }
  }

  & .form {
    margin-top: 32px;
  }

  & textarea {
    min-height: 220px;
  }

  & .gdpr-notice {
    margin-top: 10px;

    & a {
      color: var(--color-blue-main);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.about-career-form-heading {
  margin-bottom: 40px;
}
.copy-to-clipboard-btn{
  display: inline-flex;
  position: relative;
  top: 5rem;
  left: 95%;
  margin-right: 8px;
  height: 100%;
  background-color: transparent;
  contain: content;
  font-size: 0.75rem;

  @media (--lt-640) {
    left: 90%;
  }
}

.copy-link-svg{
  fill: #40bfef;
}
.copy-link-svg:hover{
  fill: #2283a7;
}
