.ultra-text {
  overflow: hidden;
  padding: 80px 0; /* default value, is mostly overriden at every page */
  contain: content;

  @media (--lt-1000) {
    padding: 80px 0;
  }
}

.ultra-text-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1px;
}

.ultra-text-row {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
