body.page-about-polygon {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

.about-polygon-text2-box {
  margin-bottom: 40px;
  padding: 40px;
  width: calc(100% + 120px);

  @media (--lt-1200) {
    width: 100%;
    padding: 32px;
  }
}

#about-polygon-text1 {
  margin-bottom: 200px;

  @media (--lt-1000) {
    margin-bottom: 100px;
  }
}

#about-polygon-text2 {
  @media (--lt-1200) {
    padding: 100px 0 50px;
  }

  @media (--lt-640) {
    padding: 50px 0;
  }

  & .container-image > img {
    height: 100%;
    max-width: none;
    left: 15%;
    transform: translate(0%, -50%);
  }
}

#about-polygon-bigimage {
  & img {
    width: 100%;
    height: auto;
  }
}
