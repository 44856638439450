#hp-intro-slider {

  .section-title {
    @apply text-[34px] md:text-[40px] xl:text-[70px];
  }

  .swiper-pagination {

    .step {
      @apply relative w-[75px] h-[2px] rounded-full bg-white/50 cursor-pointer;
    }

  }


  &:not(.stopped) .step {
    &.active .progress {
      @apply absolute content-[''] left-0 top-0 h-full bg-white;
      animation-name: slideTimer;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }

  .fadeOutAnimation {
    animation-name: fadeOut;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      display: none;
    }
  }

  &.stopped .step.active {
    @apply bg-blue;
  }

  @keyframes slideTimer {
    from {
      @apply w-0;
    }
    to {
      @apply w-full;
    }
  }

}