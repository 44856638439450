.btn {
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  border: 0;
  outline: none;
  cursor: pointer;
}

.btn-solid {
  padding: 1em 2em;
  text-decoration: none;
  background-color: var(--color-blue-main);
  color: var(--color-white);
  font-size: 11px;
  line-height: 2.1;
  letter-spacing: 1px;
  position: relative;
  transition: all 0.15s ease-in-out;

  &.btn-fond-extra {
    background-color: var(--color-gray-2);
    font-size: 14px;
  }

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    background-color: var(--color-blue-darker);
  }

  &\-transparent {
    background-color: transparent;
  }
}

.btn-text {
  position: relative;
  z-index: 2;
}

.btn-square-arrow,
.btn-square-arrow-double,
.btn-square-arrow-text {
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
  }

  &:after {
    z-index: 1;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
  }

  &\-up:after {
    transform: rotate(-90deg);
  }
  &\-right:after {
    transform: rotate(0deg);
  }
  &\-down:after {
    transform: rotate(90deg);
  }
  &\-left:after {
    transform: rotate(180deg);
  }
}

.btn-square-arrow,
.btn-square-arrow-text {
  &:after {
    background-image: url("images/arrow_white.svg");
  }
}

.btn-square-arrow-double {
  &:after {
    background-image: url("images/arrow_double_white.svg");
  }
}

.btn-square-arrow,
.btn-square-arrow-double {
  height: 32px;
  width: 32px;
  background-color: var(--color-blue-main);
  transition: background-color 0.15s ease-in-out;
  contain: strict;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    background-color: var(--color-blue-darker);
  }

  &:after {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;

    @media (--lt-1000) {
      transition-duration: 0s;
    }
  }

  &\-small {
    height: 24px;
    width: 24px;
  }

  &\-large {
    height: 40px;
    width: 40px;
  }
}

.btn-square-arrow-text {
  background-color: transparent;
  line-height: 1;
  padding: 0.5em 1em 0.5em 4em;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
  font-size: 11px;
  line-height: 2.1;
  letter-spacing: 1px;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:before,
  &:after {
    height: 3em;
    width: 3em;
  }

  &:before {
    z-index: -1;
    transition: width 0.15s ease-in-out;
    background-color: var(--color-blue-main);

    @media (--lt-1000) {
      transition-duration: 0s;
    }
  }

  &:hover {
    color: var(--color-white);
    &:before {
      width: 100%;
    }
  }

  &\-transparent {
    &:before {
      background-color: transparent;
      border: 1px solid var(--color-white);
      box-sizing: border-box;
    }

    &:hover {
      color: black;
      &:before {
        background-color: white;
      }
      &:after {
        background-image: url(images/arrow_black.svg);
      }
    }
  }
}

.btn-play {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--color-blue-main);
  transition: background-color 0.15s ease-in-out;
  background-image: url("images/play_arrow.svg");
  background-repeat: no-repeat;
  background-position: 53% 50%;
  contain: strict;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    background-color: var(--color-blue-darker);
  }
}

.btn-square-close {
  width: 40px;
  height: 40px;
  background-color: var(--color-blue-main);
  transition: background-color 0.15s ease-in-out;
  background-image: url("images/icon_x_white.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    background-color: var(--color-blue-darker);
  }
}
