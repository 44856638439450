.film-festival {
  width: 165px;

@media (--lt-1000) {
  width: 150px;
}

@media (--lt-528) {
  width: 140px;
}
}

.kviff-homepage-slide {
  position: relative;
  justify-content: center;
  padding: 100px 0 180px;
  background-image: url("images/homepage/texture_bg.jpg");

  @media (--lt-1000) {
    justify-content: space-around;
  }

  @media (--lt-480) {
    padding-top: 10%;
  }
}

.kviff-homepage-slide-container {
  position: relative;
  z-index: 2;
}

.kviff-homepage-slide-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (--lt-1000) {
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
  }
}

.kviff-homepage-slide-text {
  display: flex;
  flex-direction: column;
  gap: 68px;
  width: 60%;

  @media (--lt-1400), (max-height: 900px) {
    gap: 61px;
  }

  @media (--lt-1000) {
    width: 100%;
    gap: 34px;
  }

  @media (--lt-480) {
    gap: 20px;
  }
}

.kviff-homepage-header-text {
  width: 100%;
  font-family: "Hatton", serif;
  font-size: 100px;
  line-height: 126px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (--lt-1400), (max-height: 900px) {
    font-size: 75px;
    line-height: 95px;
  }

  @media (--lt-640) {
    font-size: 33px;
    line-height: 50px;
  }
}

.kviff-homepage-sticker-part {
  display: flex;
}

.kviff-homepage-sticker {
  width: 300px;
  height: 125px;
  margin-left: -2%;
  margin-right: -2%;
  z-index: -1;
  background-image: url("images/homepage/accol_sticker.svg");
  background-size: cover;

  @media (--lt-1400), (max-height: 900px) {
    width: 225px;
    height: 94px;
  }

  @media (--lt-640) {
    width: 110px;
    height: 45px;
  }
}

.kviff-homepage-sub-text {
  font-family: "Gotham";
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 1px;

  @media (--lt-1400), (max-height: 900px) {
    font-size: 25px;
    line-height: 30px;
  }

  @media (--lt-1000) {
    font-size: 21px;
    line-height: 26px;
  }

  @media (--lt-640) {
    font-size: 12px;
    line-height: 14px;
  }
}

.kviff-homepage-slide-acc-logo {
  display: block;
  width: 467px;
  height: 599px;
  background-size: cover;
  background-image: url("images/homepage/2023-04_kviff.png");

  @media (--lt-1400) {
    width: 428px;
    height: 533px;
  }

  @media (--lt-1000) {
    display: none;
  }
}

.kviff-homepage-slide-logo {
  display: none;
  background-image: url("images/homepage/kviff_logo.svg");
  background-size: 100% 100%;

  @media (--lt-1000) {
    display: block;
    width: 120px;
    height: 100px;
  }

  @media (--lt-640) {
    width: 90px;
    height: 75px;
  }
}

.kviff-homepage-slide-btn-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;

  /*@media (--lt-640) {*/
  /*  margin-top: 50px;*/
  /*}*/

  /*@media (--lt-480) {*/
  /*  margin-top: 10px;*/
  /*}*/
}

.kviff-button {
  width: fit-content;
  margin-top: 40px;
}

.english-part {
  margin-right: 4%;
}
