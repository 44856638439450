.download-slider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1550px;
  padding-left: 10px;
  padding-right: 10px;
}

.download-slider-header {
  margin-bottom: 2.5em;
}

.download-slider-item {
  background-color: var(--color-white);
  padding: 30px;
  width: 500px;
  transition: all 500ms ease-in-out;

  @media (--lt-800) {
    width: auto;
  }

  &:not(.swiper-slide-active) {
    opacity: 0.7;
    transform: scale(0.85);
  }

}

.download-slider-item-header {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  & h3 {
    margin: 0;
  }
}

.download-slider-item-image {
  display: block;
  margin: 0 auto 30px;
}

.download-slider-controls {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
}

.download-slider-item .locale-select .current-locale {
  text-decoration: none;
}
.download-slider-item .download-btn-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (--lt-1400) {
    flex-direction: column;
  }
}

.locale-download-short {
    display: inline;
}

.download-slider-item .download-btn-wrap .download-btn {
  margin-right: 1rem;
}

.download-slider .swiper-container {
  padding-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  overflow: hidden;
}