b,
strong {
  font-weight: 700;
}

.typo-family-trump,
h1,
.typo-size-h1,
h2,
.typo-size-h2,
h3,
.typo-size-h3,
.typo-size-ultra {
  font-family: "Trump";
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.typo-size-ultra-fond {
  font-family: "Trump";
  font-weight: 700;
  font-size: 100px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.typo-size-h1.typo-family-gotham {
  font-family: "Gotham";
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-transform: none;
}

h4,
.typo-size-h4,
h5,
.typo-size-h5,
h6,
.typo-size-h6,
.typo-size-label {
  font-family: "Gotham", sans-serif;
  letter-spacing: 0;
  text-transform: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.8em;
}

/* HEADLINE 56 */
h1,
.typo-size-h1 {
  font-size: 56px;
  line-height: 1.3;

  @media (--lt-1400) {
    font-size: 46px;
  }

  @media (--lt-1000) {
    font-size: 40px;
  }

  @media (--lt-480) {
    font-size: 28px;
  }
}

/* HEADLINE 40 */
h2,
.typo-size-h2 {
  font-size: 40px;
  line-height: 1.3;

  @media (--lt-1400) {
    font-size: 36px;
  }

  @media (--lt-1000) {
    font-size: 32px;
  }

  @media (--lt-480) {
    font-size: 28px;
  }
}

/* HEADLINE 24 */
h3,
.typo-size-h3 {
  font-size: 24px;
  line-height: 1.5;

  @media (--lt-1000) {
    font-size: 20px;
  }

  @media (--lt-480) {
    font-size: 18px;
  }
}

/* HEADLINE 15 */
h4,
.typo-size-h4 {
  font-size: 15px;
  line-height: 1.8;
  font-weight: 700;

  @media (--lt-480) {
    font-size: 13px;
  }
}

/* HEADLINE 120 */
.typo-size-ultra {
  font-size: 120px;
  line-height: 1.2;

  @media (--lt-1000) {
    font-size: 80px;
  }

  @media (--lt-480) {
    font-size: 42px;
  }
}

/* PARAGRAPH */
p,
.typo-size-p {
  font-size: 15px;
  line-height: 1.8;

  @media (--lt-480) {
    font-size: 13px;
    line-height: 1.6;
  }
}

p {
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 0;
  }
}

p + p {
  margin-top: 1em;
}

.typo-size-p-small,
p.typo-size-p-small,
.typo-size-p-small p,
p small {
  font-size: 13px;
  line-height: 1.8;

  @media (--lt-480) {
    font-size: 11px;
  }
}

.typo-size-p-big,
p.typo-size-p-big,
.typo-size-p-big p {
  font-size: 22px;
  line-height: 2.1;

  @media (--lt-800) {
    font-size: 18px;
    line-height: 2;
  }

  @media (--lt-480) {
    font-size: 15px;
    line-height: 1.9;
  }
}

h6,
.typo-size-h6,
.typo-size-label {
  font-size: 11px;
  font-weight: 700;
  line-height: 2.1;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (--lt-480) {
    font-size: 10px;
    line-height: 1.75;
  }
}

ul + p {
  margin-top: 1em;
}

/* COLORS */
.typo-color-white {
  color: var(--color-white);
}

.typo-color-black {
  color: var(--color-black);
}

.typo-color-blue {
  color: var(--color-blue-main);
}

.typo-color-gray-blue {
  color: var(--color-gray-blue);
}

/* TEXT */
.typo-center {
  text-align: center;
}

.typo-uppercase {
  text-transform: uppercase;
}

/* LINKS */
a {
  color: inherit;
}

.typo-link-textlike {
  &,
  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &.typo-color-blue {
    color: var(--color-blue-main);
  }
}

i {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: 0.5em;
}

.heading-with-bg {
  position: relative;
  display: inline-block;
  padding: 80px 0;
  margin: 0;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: calc((100vw - 1400px) / -2);
    top: 0;
    width: calc(100% + (100vw - 1400px) / 2 + 64px);
    min-width: 200px;
    height: 100%;
    background-color: var(--color-white);

    @media (--lt-1500) {
      left: -64px;
      width: calc(100% + 64px + 64px);
    }

    @media (--lt-1000) {
      left: -32px;
      width: calc(100% + 32px + 32px);
      height: 220px;
    }
  }
}

.h3-contacts {
  padding-top: 15px;
}
