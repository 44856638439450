.download-button {
  contain: layout;
  &:hover {
    & .download-button-icon {
      animation: pulse-blue 3s infinite;
    }
  }
}

.download-button-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: none;

  & svg {
    width: 38px;
    height: 38px;
    fill: var(--color-white);
  }
}

.download-button-text {
  width: calc(100% - 64px);
  padding-left: 24px;
  & > * {
    margin: 0;
  }
}
