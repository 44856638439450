.person-card {
  contain: content;
  position: relative;
}

.person-card-image {
  background-color: var(--color-white);
  margin-bottom: 32px;

  @media (--lt-480) {
    margin-bottom: 16px;
  }
}

.person-card-text-name {
  font-family: "Gotham";
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
}

.person-card-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
}
