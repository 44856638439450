.slider-controls {
  align-items: center;
}

.slider-controls-counter {
  margin-right: 25px;
  width: auto;

  @media (--lt-1000) {
    display: none;
  }
}

.slider-controls-left,
.slider-controls-right {
  position: relative;
  z-index: 2;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 2px solid var(--color-black);
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: auto;
    background-repeat: no-repeat;
  }

  &:hover {
    background-color: var(--color-blue-main);
  }
}

.slider-controls-left {
  left: 1px;
  &:before {
    background-image: url("images/arrow_black.svg");
    transform: rotate(180deg);
  }
}

.slider-controls-right {
  right: 1px;
  &:before {
    background-image: url("images/arrow_black.svg");
  }
}

.swiper-container {
  overflow: visible;
}
