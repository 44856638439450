/* LAYOUT */

/* html{
  scroll-snap-type: y proximity;

  @media (--lt-1000){
    scroll-snap-type: none;
  }
} */
body {
  font-family: "Gotham", sans-serif;
  color: var(--color-black);
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
  position: relative;

  &:not(.dark) {
    background-color: var(--color-blue-ultralight);
  }
  &.dark {
    background-color: #36383A;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  display: inline-block;
}

p + ul,
p + ol {
  margin-top: 32px;
}

ul:where(:not(.disc), :not(.tracy-row)) {
  & li {
    padding-left: 32px;
    background-image: url("images/list_bullet_white.svg");
    background-repeat: no-repeat;
    background-position: left 48%;
  }

  & li + li {
    margin-top: 0.5em;
  }

  &.list-bullet-white {
    & li {
      background-image: url("images/list_bullet_white.svg");
    }
  }
  &.list-bullet-gray {
    & li {
      background-image: url("images/list_bullet_blue.svg");
    }
  }
}

ul.disc {
  list-style: disc;
  padding-left: 1em;
}

section {
  width: 100%;
  contain: content;

  &.sticky-parallax {
    position: sticky;
    bottom: 0;
    scroll-snap-align: start;
    scroll-snap-stop: normal;

    @media (--lt-1000) {
      position: relative;
    }
  }

  &.m-bg-gray-1 {
    margin-bottom: -1px;
  }
}

.section-side {
  padding: 120px 0;
  display: flex;
  flex-wrap: wrap;

  & > .container {
    position: relative;
    z-index: 3;
    width: 50%;
    height: 100%;
    padding-left: calc((100% - 1500px) / 2);
    padding-right: 120px;
    padding-top: 120px;
    padding-bottom: 120px;
    margin: 0;

    @media (--lt-1500) {
      padding-left: 56px;
      padding-right: 56px;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @media (--lt-1000) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @media (--lt-640) {
      background-color: transparent;
      width: 100%;
      margin-left: 0;
    }

  }
  
  & > .container--right {
    padding-left: 120px;
    padding-right: calc((100% - 1500px) / 2);

    @media (--lt-1500) {
      padding-left: 56px;
      padding-right: 56px;
    }

    @media (--lt-1000) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  & > .container-image {
    width: 50%;
    overflow: hidden;

    @media (--lt-640) {
      width: 100%;
      margin-top: 32px;
    }

    & img {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

time {
  text-transform: uppercase;
}

button {
  font-family: inherit;
  border: none;
}

.section-fullscreen {
  width: 100%;
  height: 100vh;
  contain: strict;
}

.section-fullscreen-min {
  width: 100%;
  min-height: 100vh;

  @media (--lt-1000) {
    min-height: 0;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;

  @media (--lt-1500) {
    max-width: 100%;
    padding-left: 56px;
    padding-right: 56px;
  }

  @media (--lt-1000) {
    padding-left: 32px;
    padding-right: 32px;
  }

  &\-auto {
    width: auto;
  }

  &\-md {
    max-width: 700px;
  }

  &\-lg {
    max-width: 925px;
  }

  &\-xl {
    max-width: 1080px;
  }

  &\-2xl {
    max-width: 1216px;
  }

  &\-3xl {
    max-width: 1464px;
  }

  &\-4xl {
    max-width: 1712px;
  }

  &\-half {
    max-width: 50%;
  }
}

/* POSITIONING */

.l-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.l-center-x {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.l-center-y {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

img.l-center,
img.l-center-x,
img.l-center-y {
  display: block;
}

/* FLEXBOX */

.l-flex {
  display: flex;
  flex-wrap: wrap;
}

.l-flex-nowrap {
  flex-wrap: nowrap;
}

.l-flex-column {
  flex-direction: column;
}

/* GRID */

.l-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(calc(100% / 4 - 32px), 1fr));
  grid-template-rows: auto;
  column-gap: 32px;
  row-gap: 32px;
  justify-content: space-between;

  @media (--lt-800) {
    column-gap: 20px;
    row-gap: 20px;
  }
}

.l-grid-gap {
  display: grid;
  grid-template-columns: repeat(4, minmax(calc(100% / 4 - 32px), 1fr));
  grid-template-rows: auto;
  column-gap: 32px;
  row-gap: 32px;
  justify-content: space-between;

  @media (--lt-800) {
    column-gap: 20px;
    row-gap: 20px;
  }
}

/* OTHERS */

.is-invisible {
  visibility: hidden;
}

.blue-menu-rectangle{
  color: var(--color-white);
  background-color: #66CCCC;
}
.section-text-icon{

@media (--lt-800) {
  width: 7%;
}
}

.hidden{
  display: none;
}

#miniature-image{
  margin-top: 1rem;
}
#miniature-image:hover{
  fill: #40BFEFFF !important;
}

[x-cloak] { display: none !important; }

html.tracy-bs-visible body {
  overflow: unset !important;
}

a[disabled] {
  @apply grayscale pointer-events-none;
}
[invisible] {
  @apply invisible;
}

.image-cover {
  @apply absolute left-0 top-0 w-full h-full object-cover;
}
.image-contain {
  @apply absolute left-0 top-0 w-full h-full object-contain;
}