/* PARK DETAIL */
body.page-park-detail {
  padding-top: 160px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

.park-detail-textimage1-location {
  margin-bottom: 3.5em;
}

.park-detail-textimage1-breeam {
  margin-top: 40px;
}

/* PARK DETAIL GENERAL INFO SECTION */
#park-detail-info {
  &.section-side {
    padding: 0;

    & .container {
      padding-top: 0;
      padding-bottom: 0;
    }

    & .container.park-detail-info-text,
    & .container-image.park-detail-info-map {
      @media (--lt-1000) {
        width: 100%;
      }
    }

    & .container-image.park-detail-info-map {
      @media (--lt-1000) {
        margin-top: 40px;
      }
    }
  }
}

.park-detail-info-title {
  margin: 0;
}

.park-detail-info-text-list {
  padding: 50px 40px;
  margin: 40px -100px 40px 0;

  @media (--lt-1000) {
    margin: 40px 0;
    padding: 32px;
  }

  & ul li {
    background-image: url("images/list_bullet_blue.svg");
  }
}

.park-detail-info-map {
  & img {
    max-height: 700px;
  }
}

/* PARK DETAIL TENANTS SECTION */
#park-detail-tenants {
  padding: 100px 0;
}

.park-detail-tenants-title {
  margin-bottom: 60px;

  @media (--lt-1000) {
    margin-bottom: 0.8em;
  }
}

.park-detail-tenants-grid {
  margin-bottom: 40px;

  @media (--lt-800) {
    grid-template-columns: repeat(3, minmax(calc(100% / 3 - 16px), 1fr));
  }
  @media (--lt-640) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 16px), 1fr));
  }

  &.collapsed {
    & .park-detail-tenants-grid-item:nth-child(n + 5) {
      display: none;
    }
  }
}

.park-detail-tenants-grid-item {
  padding: 32px 60px;

  @media (--lt-1400) {
    padding: 20px 40px;
  }
  @media (--lt-1000) {
    padding: 10px;
  }

  &:hover {
    background-color: var(--color-white);
    box-shadow: var(--color-gray-shadow) 0 20px 80px -10px;

    & img {
      filter: grayscale(0%);
    }
  }
}

.park-detail-tenants-grid-item-image {
  height: 100px;
  width: 100px;
  margin: 0 auto 30px;

  & img {
    max-height: 100%;
    filter: grayscale(100%);
  }
}

.park-detail-tenants-grid-item-category {
  text-transform: uppercase;
}

.park-detail-tenants-grid-loadmore {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 1em 4em;
}

/* PARK DETAIL BUILDINGS SECTION */
#park-detail-buildings {
  padding-bottom: 120px;
  padding-top: 150px;

  @media (--lt-1000) {
    padding-top: 0;
  }

  & > .container {
    align-items: center;
  }

  table.building-card-backside-parameters {
    tr td:first-child {
      white-space: nowrap;
    }
  }
}

.park-detail-buildings-map,
.park-detail-buildings-slider {
  width: 50%;

  @media (--lt-800) {
    width: 100%;
  }
}

.park-detail-buildings-map {
  padding-right: 100px;

  @media (--lt-1200) {
    padding-right: 50px;
  }

  @media (--lt-800) {
    padding-right: 0;
    margin-bottom: 50px;
  }

  & svg {
    max-height: 700px;
  }
}

.park-detail-buildings-slider {
  overflow: hidden;
  padding: 0 0 100px;
  margin: 0 0 -100px;

  & h1 {
    font-size: 40px;
  }

  &[data-item-count="2"] {
    & .slider-controls {
      display: none;

      @media (--lt-1000) {
        display: block;
      }
    }
  }
  &[data-item-count="1"],
  &[data-item-count="0"] {
    & .slider-controls {
      display: none;
    }
  }
}

.park-detail-download-grid {
  grid-template-columns: repeat(2, minmax(calc(100% / 2 - 16px), 1fr));
  @media (--lt-800) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 16px), 1fr));
  }
}

.park-detail-download-grid-item {
  padding: 40px;
}

/* PARK DETAIL 360 IFRAME SECTION */
#park-detail-360 {
  margin-bottom: 120px;

  & iframe {
    width: 100%;
    height: 700px;

    @media (--lt-1000) {
      height: 550px;
    }
  }
}

#park-detail-download {
  @media (--lt-1000) {
    padding-bottom: 100px;
  }
}

#park-detail-gallery {
  margin-top: 160px;

  @media (--lt-1000) {
    margin-top: 60px;
  }
}
