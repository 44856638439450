input,
textarea {
  border: 0;
  border-radius: 0;
  outline: none;
  --tw-ring-shadow: 0 0 #000 !important;
  outline-offset: -1px !important;

  &::input-placeholder {
    color: var(--color-black);
    font-size: 11px;
    font-weight: 700;
    line-height: 2.1;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &:input-placeholder {
    color: var(--color-black);
    font-size: 11px;
    font-weight: 700;
    line-height: 2.1;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &::placeholder {
    color: var(--color-black);
    font-size: 11px;
    font-weight: 700;
    line-height: 2.1;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &:focus {
    outline-color: #2563eb !important;
    outline-width: 1px !important;
  }

  &.invalid {
    outline: solid 1px var(--color-red);
  }

  &.invalid + .form-field-checkbox-mark {
    outline: solid 1px var(--color-red);
    outline-offset: 0;
  }
}

input {
  &.typo-size-label {
    @media (--lt-480) {
      font-size: 11px;
    }
  }
}

textarea {
  border: none;
  padding: 24px 30px;
  min-height: 200px;
  font-family: inherit;
}

input[type="text"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="date"] {
  padding: 12px 30px;
}

input[type="file"] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}

input[type="search"] {
  padding-left: 65px;
  background-image: url("images/icon_search_blue.svg");
  background-repeat: no-repeat;
  background-position: 32px center;

  @media (--lt-1000) {
    padding-left: 55px;
    background-position: 24px center;
  }
}

.form {
  column-gap: 16px;
  row-gap: 16px;
}
/*
For format form-grid-X-col
X is number of desired columns
*/
.form-grid-2-col {
  grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
}
.form-grid-3-col {
}

.form-field {
  position: relative;
}

/*
For format .form-field-X-Y
X is for grid-column-start
Y is for grid-column-end
Counting column lines of grid, for more info see https://css-tricks.com/snippets/css/complete-guide-grid/
*/

.form-field-1-3 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.form-field-2-3 {
  grid-column-start: 2;
  grid-column-end: 3;

  @media (--lt-800) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.form-field-1-2 {
  grid-column-start: 1;
  grid-column-end: 2;

  @media (--lt-800) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

/* CHECKBOX WITH LABEL */
.form-field-checkbox {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  display: block;

  &:hover {
    & .form-field-checkbox-mark:after {
      opacity: 0.3;
    }
  }
}

.form-field-checkbox + .form-field-checkbox {
  margin-top: -12px;
}

.form-field-checkbox-mark {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    background-color: var(--color-blue-ultralight);
  }

  &:after {
    opacity: 0;
    background-image: url("images/icon_x_blue.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.form-field-checkbox-input:checked + .form-field-checkbox-mark {
  &:after {
    opacity: 1;
  }
}

.form-field-checkbox-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
}

/* UPLOAD INPUT */
.form-field-upload {
  cursor: pointer;
  margin: 16px 0;

  &:hover {
    & .form-field-upload-mark {
      background-color: var(--color-blue-darker);
    }
  }
}

.form-field-upload.l-flex {
  align-items: center;
}

.form-field-upload-mark {
  width: 56px;
  height: 56px;
  background-color: var(--color-blue-main);
  background-image: url("images/icon_circle_plus.svg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.15s ease-in-out;
}

.form-field-upload-text {
  width: calc(100% - 56px);
  padding-left: 25px;
}

.form-field-submit {
  justify-self: start;
  margin-top: 16px;
}

form.loading {
  @apply grayscale pointer-events-none;
}