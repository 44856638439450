/* PARK LIST */
body.page-park-list,
body.page-planned-park-list {
  & .page-footer {
    display: none;
  }
}

body.page-park-list {
  background-color: var(--color-gray-2);
  & .page-header.headroom--top {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-white);
    }
  }

  & .page-header.headroom--top.is-active {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-black);
    }
  }
}

.park-list-wrap {
  position: relative;
  overflow: hidden;

  @media (--lt-1000) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    contain: layout;
  }

  &.section-fullscreen {
    @media (--lt-1000) {
      height: auto;
    }
  }

  & .background-line {
    z-index: 1;
  }
}

.park-list-content {
  position: relative;
  z-index: 2;
  left: calc((100vw - 1400px) / 2);
  width: 606px;
  height: 100%;
  padding: 160px 40px 80px 0;
  overflow-y: auto;
  scrollbar-width: none;

  @media (--lt-1500) {
    left: 0;
    padding-left: 56px;
  }

  @media (--lt-1000) {
    order: 2;
    left: 0;
    width: 100%;
    height: auto;
    padding: 40px 32px;
    background-color: var(--color-blue-ultralight);
    color: var(--color-black);
  }
}

/* Visualy hide scrollbar but still be able to scroll */
/* .park-list-content::-webkit-scrollbar{width: 0 !important; } */ /* Chrome, Safari, newer versions of Opera */
/* .park-list-content { overflow: -moz-scrollbars-none; } */ /* Firefox */
/* .park-list-content { -ms-overflow-style: none; } */ /* Internet Explorer +10 */

.park-list-content::-webkit-scrollbar {
  width: 2px;
}
.park-list-content {
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue-main) rgba(0, 0, 0, 0);
}
.park-list-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.park-list-content::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-main);
  border-radius: 0;
  border: 2px solid rgba(0, 0, 0, 0);
}

.park-search-form {
  width: 100%;
  margin: 40px 0;
  grid-template-columns: repeat(3, minmax(calc(33% - 16px), 1fr));
  column-gap: 16px;
  row-gap: 16px;

  @media (--lt-1000) {
    grid-template-columns: repeat(4, minmax(calc(25% - 16px), 1fr));
  }

  @media (--lt-800) {
    grid-template-columns: repeat(3, minmax(calc(33% - 16px), 1fr));
  }

  @media (--lt-480) {
    grid-template-columns: repeat(2, minmax(calc(50% - 16px), 1fr));
  }

  & .form-field-checkbox + .form-field-checkbox {
    margin-top: 0;
  }
}

.park-search-form-search-field {
  width: inherit;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-bottom: 32px;

  @media (--lt-1000) {
    grid-column-end: 5;
  }

  @media (--lt-800) {
    grid-column-end: 4;
  }

  @media (--lt-480) {
    grid-column-end: 3;
  }
}

.park-search-form-country-field {
  & .form-field-checkbox-mark:before {
    @media (--lt-1000) {
      background-color: var(--color-white);
    }
  }
}

.park-list-content-cards {
  & > * + * {
    margin-top: 16px;
  }

  &.is-active {
    & .park-card {
      opacity: 0.5;
    }
    & .park-card.is-active {
      opacity: 1;
    }
  }

  & .park-card {
    width: 100%;
  }
}

.park-list-map {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media (--lt-1000) {
    position: static;
    height: 50vh;
    max-height: 400px;
    order: 1;
  }

  @media (--lt-640) {
    height: 40vh;
  }
}
