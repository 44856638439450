body.page-ewaste {
    padding-top: 130px;

    & footer {
        padding-top: 0;
    }

    @media (--lt-1400) {
        padding-top: 80px;
    }
}

#ewaste {
    padding: 70px 32%;
    background-color: var(--color-white);
    margin-bottom: 60px;

    @media (--lt-1000) {
        padding: 30px 10%;
    }

    @media (--lt-800) {
        padding: 30px 5%;
    }
}

.ewaste__green-text {
    color: var(--color-green-ewaste);
}

.ewaste__header {
    margin-bottom: 30px;

    & .ewaste__header-environment {
        &::after {
            display: inline-block;
            content: '';
            width: 50px;
            height: 50px;
            margin-left: 1%;
            background-image: url("images/ewaste/leaf_1.svg");
            background-size: cover;

            @media (--lt-1000) {
                width: 30px;
                height: 30px;
            }

            @media (--lt-800) {
                width: 25px;
                height: 25px;
            }
        }
    }

    & .ewaste__change-locale {
        display: block;
        width: fit-content;
        padding: 15px 30px;
        font-weight: bold;
    }
}

.ewaste__section {
    margin-bottom: 70px;

    & h3 {
          margin-bottom: 8px;
    }

    & .ewaste__section-text {
        & p {
          margin-bottom: 40px;
        }
    }
}

.ewaste__intro-section p {
    font-style: italic;
    font-size: 18px;
}

.ewaste__section-green {
    position: relative;
    padding: 45px;
    color: var(--color-white);
    background-color: var(--color-green-ewaste);
    border-radius: 20px;

    & h3 {
        text-transform: uppercase;
        font-size: 28px;
        margin-bottom: 28px;
    }

    & ul {
        & li {
            padding-left: 35px;
            background-position: left 5px;
            background-size: 28px;
        }

        & li + li {
            margin-top: 40px;
        }
    }

    & .ewaste__section-green__leaf {
      position: absolute;
      background-size: cover;
    }

    & .ewaste__section-green__leaf1 {
      background-image: url("images/ewaste/leaf_2.svg");
      width: 35px;
      height: 35px;
      top: -28px;
      right: 25%;
    }

    & .ewaste__section-green__leaf2 {
      background-image: url("images/ewaste/leaf_3.svg");
      width: 65px;
      height: 50px;
      top: -43px;
      right: 0;
    }

    & .ewaste__section-green__leaf3 {
      background-image: url("images/ewaste/leaf_4.svg");
      width: 87px;
      height: 34px;
      bottom: -27px;
      right: 0;
    }
}

.ewaste__section-types {
    & ol {
        & .ewaste__section-types__list-item {
            & ul {
                list-style: disc;

                & li {
                    padding-left: 0;
                    margin-left: 24px;
                    margin-top: 0;
                }
            }
        }

        & .ewaste__section-types__list-item + .ewaste__section-types__list-item {
            margin-top: 40px;
        }
    }
}