body.page-about-bonds, body.page-about-bonds-detail {
    padding-top: 130px;
  
    @media (--lt-1400) {
      padding-top: 100px;
    }
}

#about-bonds-items {
  padding-top: 100px;
}

.about-bonds-filter-button {
  &.is-active {
    background-color: var(--color-white);
    color: var(--color-blue-main);
  }
}

.about-bonds-container {
    padding-top: 50px;
    contain: layout;
}

.about-bonds-heading {
  margin-bottom: 60px;

  @media (--lt-800) {
    margin-bottom: 30px;
  }
}

.about-bonds-list {
  padding: 70px 0;
  display: grid;
  grid-template-columns: 1fr;
}

.about-bonds-text {
  display: inline-block;
  width: 100%;
  column-gap: 10px;

  @media (--lt-1000) {
    width: 50%;
  }

  @media (--lt-800) {
    width: 70%;
  }
}

.about-bonds-item-name {
  width: 100%;
  padding-top: 2px;
}

.about-bonds-list-item {
  border-top: 1px solid var(--color-black);
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &:last-child {
    border-bottom: 1px solid var(--color-black);
  }

  & h3 {
    margin: 0;
  }

  &.is-hidden {
    display: none;
  }
}

.about-bonds-emissions-list {
  padding-top: 80px;
}

.about-bonds-emissions-list ul {
  padding-left: 26px;
  margin-bottom: 32px;
}

.about-bonds-emissions-list li {
  background-image: url("images/list_bullet_blue.svg");
  background-position: left 9px;
}

.about-bonds-list-item-button {
  display: flex;
  gap: 24px;
  align-items: center;
  text-decoration: none;
  justify-content: end;

  @media (--lt-800) {
    justify-content: start;
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover .btn-square-arrow {
    background-color: var(--color-blue-darker);
  }

  & .btn-square-arrow {
    flex-shrink: 0;
  }
}


.about-bonds-detail {
  background-color: var(--color-white);
  margin: 0 15%;
  padding: 5% 10%;
  word-wrap: break-word;

  @media (--lt-1000) {
    margin: 0;
  }
}