body.page-about-default {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#about-default-textimage1 {
  @media (--lt-1000) {
    margin-bottom: 0;
  }
}

#about-default-graph {
  padding-top: 450px;
  padding-bottom: 100px;
  margin-top: -400px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;

  @media (--lt-1000) {
    margin-top: 0;
    padding: 50px 0;
  }

  @media (--lt-640) {
    display: none;
  }
}

.about-default-graph-container {
  padding-right: 3%;
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  max-height: 1000px;
}

.about-default-graph-animation {
  height: 100%;
  width: 100%;
}

#about-default-esg {
  padding-bottom: 560px;
  margin-bottom: -400px;

  @media (--lt-1000) {
    padding-bottom: 100px;
    margin-bottom: 100px;
  }

  @media (--lt-640) {
    padding-top: 40px;
  }

  & > .container {
    align-items: center;
    justify-content: space-evenly;
  }
}

.esg-text {
  width: 35%;
  padding-right: 80px;

  @media (--lt-1200) {
    padding-right: 0;
  }

  @media (--lt-800) {
    width: 100%;
    margin-bottom: 40px;
  }

  & a {
    margin-top: 40px;
  }
}

.esg-rules {
  width: 50%;
  padding: 80px 120px;

  @media (--lt-1200) {
    padding: 40px 60px;
  }

  @media (--lt-800) {
    width: 100%;
  }

  @media (--lt-480) {
    padding: 40px 20px;
  }
}

.esg-rules-item {
  align-items: center;

  &.environmental svg {
    fill: var(--color-green);
  }
}

.esg-rules-item + .esg-rules-item {
  margin-top: 32px;
}

.esg-rules-item-letter {
  width: 50px;

  @media (--lt-480) {
    text-align: center;
  }
}

.esg-rules-item-title {
  align-items: center;
  & svg {
    width: 2em;
    height: 2em;
    margin-right: 1em;
    fill: var(--color-blue-main);
  }
}

.esg-rules-item-text {
  width: calc(100% - 50px);
  padding-left: 80px;

  @media (--lt-1000) {
    padding-left: 40px;
  }

  @media (--lt-480) {
    padding-left: 20px;
  }
}

#about-default-joinus {
  margin-top: 100px;

  @media (--lt-1000) {
    margin-top: 50px;
  }
}

#about-default-textimage2 {
  z-index: 2;

  @media (--lt-1000) {
    margin-bottom: 0;
  }

  & .section-part-image.m-box-shadow {
    box-shadow: var(--color-gray-2) 0 20px 80px -20px;
  }

  & .section-part-text {
    @media (--lt-1200) {
      background-color: var(--color-white);
      padding: 120px 32px 64px;
      margin-top: -70px;
      width: calc(100% + 64px);
      max-width: calc(805px + 64px);
    }

    @media (--lt-1000) {
      max-width: none;
    }
  }
}

#about-default-textimage3 {
  margin-top: 160px;

  @media (--lt-1000) {
    margin-top: 80px;
  }

  & .section-part-image.m-box-shadow {
    box-shadow: var(--color-gray-2) 0 20px 80px -20px;
  }
}

#about-default-textimage4 {
  & .section-part-image.m-box-shadow {
    box-shadow: var(--color-gray-2) 0 20px 80px -20px;
  }
}
