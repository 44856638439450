@tailwind base;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  .img-fit {
    @apply absolute top-0 left-0 w-full h-full object-cover;
  }
  .absolute-fit {
    @apply absolute top-0 left-0 w-full h-full;
  }
}