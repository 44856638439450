body.page-about-download {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }

  & section + section {
    margin-top: 60px;

    @media (--lt-1400) {
      margin-top: 60px;
    }
  }

}

#about-download-notice {
  padding: 0 30%;
  font-weight: bold;

  @media (--lt-1000) {
    padding: 0;
  }
}