[data-in-iframe] {
  & .page-header-mobile-toggle,
  & .page-header-nav,
  & .page-footer-menu {
    display: none;
  }
}

body.page-fond {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#fond-hero-textimage5 {
  padding-bottom: 40px;
}

#fond-textimage1 {
  & .youtube-video-curtain {
    background-image: url("images/fond/fond_intro.jpg");
  }
}

.fond-textimage1-inrev {
  margin-top: 40px;
  display: block;
  width: 50%;
}

#fond-numbers {
  padding: 40px 0;
  overflow: hidden;

  & > .container {
    justify-content: space-between;
  }
}

.fond-numbers-symbol {
  width: 50%;
  @media (--lt-1300) {
    width: calc(45% - 80px);
  }

  @media (--lt-800) {
    width: 100%;
    margin-bottom: 80px;
  }
}

.fond-numbers-symbol-inner {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media (--lt-1300) {
    left: 0;
    transform: none;
  }

  @media (--lt-800) {
    left: 50%;
    transform: translateX(-50%);
    height: 400px;
  }
}

.fond-numbers-symbol-text {
  position: absolute;
  z-index: 2;
  top: 77%;
  left: 7%;
  width: 180px;

  @media (--lt-800) {
    top: 75%;
    left: -1%;
    width: 100px;
  }
}

.fond-numbers-symbol-percent {
  position: absolute;
  z-index: 2;
  top: 41%;
  left: 58%;
  /* width: 57.8%; */
  /* height: 35.3%; */
  /* width: 35.3%; */
  height: 35.3%;
  width: auto;

  @media (--lt-560) {
    left: 47%;
  }
  @media (--lt-1000) {
    height: 30%;
  }
}

.fond-numbers-symbol-image {
  display: inline-block;
  position: relative;
  z-index: 1;
  max-height: 100%;
  object-fit: contain;
}

.fond-numbers-stats {
  align-content: center;
  width: 50%;
  justify-content: space-between;

  @media (--lt-800) {
    width: 100%;
  }

  & h2,
  & p {
    width: 100%;
  }

  & h2 {
    margin-bottom: 40px;
  }
  & p {
    margin-top: 40px;
  }
}

.fond-numbers-stats-item {
  width: calc(100% / 3 - 15px);

  @media (--lt-480) {
    width: 100%;
  }

  & + .fond-numbers-stats-item {
    @media (--lt-480) {
      margin-top: 32px;
    }
  }

  & .fond-numbers-stats-item-value {
    @media (--lt-1200) {
      font-size: 80px;
    }
    @media (--lt-1000) {
      font-size: 60px;
    }
    @media (--lt-800) {
      font-size: 80px;
    }
  }
}

#fond-about {
  align-items: center;
  padding: 90px 0 0;

  /*& .chart {*/
  /*  padding-top: 80%;*/
  /*}*/

  & .container-image {
    padding: 0 120px;

    @media (--lt-1600) {
      padding: 0 80px;
    }

    @media (--lt-1400) {
      padding: 0 40px;
    }

    @media (--lt-640) {
      padding: 0 32px;
    }
  }
}

#fond-ultratext1 {
  @media (--lt-1200) {
    margin-bottom: 100px;
  }

  @media (--lt-1000) {
    margin-bottom: 0;
  }
}

#fond-textimage4 {
  padding-bottom: 50px;
}

#fond-video {
  padding: 90px 0;

  @media (--lt-1200) {
    margin: 60px 0;
    padding: 0;
  }
}

.fond-video-invest-button {
  margin-top: 56px;
}

.pop-up-fond-video-invest-button {
  @media (--lt-480) {
    margin-top: 25px;
  }
}

.fond-stocks-boxes {
  margin-top: 90px;

  @media (--lt-1000) {
    margin-bottom: 60px;
  }

  & h2 {
    width: 100%;
  }

  & h3 {
    font-size: 34px;
    margin-bottom: 0;
    line-height: 1.4;
  }

  & p {
    margin-top: 40px;
  }
}

.fond-stocks-boxes-line {
  padding: 40px 0;
  justify-content: space-between;

  .fond-stocks-boxes-item {
    width: calc(33% - 16px);
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @media (--lt-1000) {
      padding: 30px 20px;
    }

    @media (--lt-800) {
      width: 100%;
    }

    & + .fond-stocks-boxes-item {
      @media (--lt-800) {
        margin-top: 20px;
      }
    }
  }
}

.fond-stocks-boxes-item-icon {
  height: 52px;
  margin-right: 24px;

  @media (--lt-1100) {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }

  @media (--lt-800) {
    width: auto;
    margin-right: 24px;
  }
}

.fond-stocks-boxes-item-value {
  flex-grow: 2;

  & .stocks-currency {
    margin-right: 24px;
  }
}

#fond-people {
  padding: 90px 0;

  @media (--lt-1000) {
    padding: 60px 0;
  }
}

.fond-people-grid {
  &.collapsed {
    & .person-card:nth-child(n + 5) {
      display: none;
    }
  }

  @media (--lt-1000) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-800) {
    row-gap: 48px;
  }

  @media (--lt-480) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.fond-people-button {
  margin-top: 56px;
}

.fond-howtofond-cards {
  grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));

  @media (--lt-1000) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-560) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.plant-cards {
  grid-template-columns: repeat(2, minmax(calc(100% / 3 - 32px), 1fr));

  @media (--lt-1000) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-560) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.fond-howtofond-card {
  contain: layout;
}

.fond-howtofond-card-inner {
  width: 100%;
  height: 100%;
  padding: 40px 64px;

  @media (--lt-1400) {
    padding: 48px;
  }

  @media (--lt-1000) {
    padding: 40px;
  }

  @media (--lt-640) {
    padding: 32px;
  }
}

.fond-howtofond-card-title {
  width: 100%;
  margin-bottom: 0;
  align-items: center;
}

.fond-howtofond-card-number {
  margin-right: 32px;
}

.fond-howtofond-card-text {
  width: 100%;
}

.fond-portfolio-grid {
  &.collapsed {
    & .park-simple-card:nth-child(n + 5) {
      display: none;
    }
  }

  @media (--lt-1000) {
    grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));
  }

  @media (--lt-800) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-480) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.fond-portfolio-button {
  margin-top: 56px;
}

.fond-contact-perex {
  margin-bottom: 80px;
}

.fond-contact-links {
  grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  margin-top: 50px;

  @media (--lt-560) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.fond-contact-form-wrapper {
  padding: 80px 120px;
  background-image: url("images/fond/contact_people.png");
  background-repeat: no-repeat;
  background-position: 85% bottom;
  background-size: 41% auto;

  @media (--lt-800) {
    padding: 80px 20px;
  }

  @media (--lt-560) {
    padding-bottom: 80%;
    background-size: 90% auto;
    background-position: center bottom;
  }

  & h3 {
    margin-bottom: 32px;
  }
}

.fond-contact-form-namelist {
  width: 40%;
  margin-top: 80px;

  @media (--lt-560) {
    width: 100%;
  }
}

.fond-contact-form-namelist-item {
  & h4 {
    font-weight: 400;
    margin: 0;
  }
  & + .fond-contact-form-namelist-item {
    margin-top: 24px;
  }
}

.fond-contact-legal {
  margin-top: 80px;

  @media (--lt-800) {
    margin-top: 40px;
  }
}

#fond-documents {
  @media (--lt-1000) {
    padding-top: 100px;
  }
}

.fond-documents-grid {
  grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));

  @media (--lt-640) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

body.page-fond-2023 {
  #fond-textimage3 {
    margin-bottom: 200px;
  }
}

.fond-upper-contact-form-wrapper {
  padding: 80px 120px;
  background-repeat: no-repeat;
  background-position: 85% bottom;
  background-size: 41% auto;

  @media (--lt-800) {
    padding: 80px 20px;
  }

  @media (--lt-560) {
    padding-bottom: 80%;
    background-size: 90% auto;
    background-position: center bottom;
  }

  & h3 {
    margin-bottom: 32px;
  }
}
.upper-contact {
  @apply flex flex-col-reverse gap-y-12 min860:flex-row min860:gap-5;
}

.upper-contact-inner {
  @apply w-auto;
}

.upper-contact-quicklinks {
  @apply mb-0;
}

.upper-contact-item {
  @apply flex flex-col justify-start my-0 gap-y-5 min860:mr-20;
}
