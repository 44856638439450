body.page-kviff {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#kviff-intro {
  & .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 150px;
    align-items: center;

    @media (--lt-1100) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 70px;
    }
  }
}

.kviff-intro-points {
  gap: 16px;
}

.kviff-intro-points-item {
  gap: 32px;
  align-items: center;

  & .kviff-intro-points-item-image {
    flex-shrink: 0;
  }

  & img {
    width: 88px;

    @media (--lt-360) {
      width: 60px;
    }
  }
}

.kviff-intro-button {
  margin-top: 46px;
}

#kviff-game {
  margin-top: 200px;
  padding-top: 100px;

  & .container {
    position: relative;
  }

  @media (--lt-1200) {
    margin-top: 60px;
  }

  @media (--lt-1000) {
    margin-top: 0;
  }
}

.kviff-cover {
  filter: saturate(0.7) contrast(0.7) grayscale(0.04) brightness(0.7);
}

.kviff-game-label {
  width: 41rem;
  max-width: 100%;
  padding: 0.5em 1em;
  background-color: #36383a;
  position: absolute;
  display: flex;
  justify-content: center;

  @media (--lt-700) {
    margin-top: 0;
    width: 80%;
    height: auto;
    padding: 14px 15px 16px;
  }

  @media (max-width: 320px) {
    margin-top: 0;
    max-width: 230px;
    height: 20%;
    margin-left: 32px;
    margin-right: 32px;
  }
}

.kviff-game-link {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kviff-game-button {
  margin: 56px auto 0;
  /* display: block; */
  display: none;
  width: max-content;
}

#kviff-info {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }

  & .kviff-info-winnigs {
    padding-right: 100px;

    @media (--lt-1200) {
      padding-right: 0;
    }
  }
}

.kviff-info-support-box {
  padding: 32px 64px;
  align-items: center;
  margin-bottom: 50px;
  gap: 32px;

  @media (--lt-480) {
    flex-direction: column;
    text-align: center;
    padding: 32px;
  }

  & img {
    width: 72px;
  }
}

p.kviff-info-winnings-text {
  margin-bottom: 40px;
}

.kviff-info-winnings-prizes {
  gap: 32px;

  & img {
    width: 88px;
    height: 88px;
  }
}

.kviff-info-winnings-prizes-item {
  gap: 32px;
  align-items: center;

  @media (--lt-560) {
    flex-direction: column;
  }

  & h3 {
    @media (--lt-560) {
      text-align: center;
    }
  }
}

#kviff-film {
  padding: 120px 0;
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }

  @media (--lt-640) {
    padding: 60px 0;
  }
}

.kviff-film-text {
  margin-bottom: 120px;

  @media (--lt-640) {
    margin-bottom: 60px;
  }
}

.kviff-film-image {
  margin-bottom: 120px;

  @media (--lt-640) {
    margin-bottom: 60px;
  }

  & img {
    margin: 0 auto;
    display: block;
  }
}

.kviff-film-screen {
  & h2 {
    margin-bottom: 56px;
  }
}

.kviff-film-screen-table {
  width: 100%;
}

.kviff-film-screen-table-row {
  border-top: 1px solid #979797;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 16px;
  align-items: center;

  @media (--lt-640) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 16px 0;
  }

  &:last-child {
    border-bottom: 1px solid #979797;
  }

  & > * {
    padding: 16px 0;
    text-align: center;

    @media (--lt-640) {
      padding: 0;
    }

    &:first-child {
      text-align: left;

      @media (--lt-640) {
        text-align: center;
      }
    }

    &:last-child {
      text-align: right;

      @media (--lt-640) {
        grid-column: 1 / -1;
        text-align: center;
      }
    }
  }
}

.kviff-film-screen-button {
  margin: 56px auto 0;
  display: block;
  width: max-content;
}

#kviff-about-accolade {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

.kviff-about-accolade-buttons {
  margin-top: 40px;
  flex-wrap: nowrap;
  gap: 32px;

  @media (--lt-480) {
    flex-direction: column;
  }

  & > * {
    flex: 1;
    text-align: center;
  }
}

/* ////////////// GAME PAGE ////////////// */
body.page-kviff-game {
  padding-top: 160px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#kviff-game-play {
  margin-top: 100px;

  @media (--lt-1000) {
    margin-top: 50px;
  }

  & #webgl-content {
    display: flex;
    justify-content: center;
  }

  & #unityContainer {
    width: 960px;
    height: 600px;
    max-width: 100%;
  }
}

#kviff-game-play-agree-screen {
  background-image: url("images/kviff/game_overlay.png");
  background-size: cover;
  background-position: center center;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  padding: 60px;
}

#kviff-game-play-info {
  margin-top: 20px;
  gap: 20px;
  align-items: center;
}

#kviff-game-textblock {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

#kviff-game-goal {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

#kviff-game-score {
  margin-top: 80px;
  & .container {
    padding: 64px 120px;

    @media (--lt-640) {
      padding: 64px;
    }

    @media (--lt-480) {
      padding: 64px 32px;
    }

    & h2 {
      margin-bottom: 40px;
    }
  }
}

.kviff-game-score-board-row {
  border-top: 1px solid #000000;
  gap: 16px;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid #000000;
  }
}

.kviff-game-score-board-row-rank {
  width: 40px;
}

.kviff-game-score-board-row-name {
  padding: 16px 0;
}

.kviff-game-score-board-row-score {
  margin-left: auto;
}

.kviff-game-score-button {
  margin: 56px auto 0;
  display: block;
  width: max-content;
}
