body.page-about-socialresponsibility,
body.page-esg-detail {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

body.page-esg-bialystok,
body.page-eco-Kielce,
body.page-theater-for-smile,
body.page-tour-kids,
body.page-mural-Gora {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

.un_global_compact {
  margin-top: 55px;
  margin-bottom: 55px;
}

#about-socialresponsibility-textimage1 {
  padding-bottom: 0;
}

#about-socialresponsibility-section2 {
  margin-bottom: 100px;

  @media (--lt-1000) {
    margin-bottom: 50px;
  }
}

#about-socialresponsibility-principles {
  padding: 100px 0;

  @media (--lt-1000) {
    padding: 50px 0;
  }
}

.socialresponsibility-principle {
  padding: 0 120px;
  justify-content: space-between;
  align-items: center;

  @media (--lt-1400) {
    padding: 0 60px;
  }

  @media (--lt-1000) {
    padding: 0 32px;
  }

  & + .socialresponsibility-principle {
    margin-top: 200px;

    @media (--lt-1400) {
      margin-top: 100px;
    }
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }

  &.environmental svg {
    fill: var(--color-green);
  }
}

.socialresponsibility-principle-card {
  width: 38%;
  height: 500px;
  perspective: 1000px;

  @media (--lt-1400) {
    width: calc(50% - 40px);
  }

  @media (--lt-1000) {
    width: calc(50% - 20px);
    margin-bottom: 32px;
  }

  @media (--lt-800) {
    width: 100%;
  }

  @media (--lt-560) {
    height: 450px;
  }
}

.socialresponsibility-principle-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.25s linear;
  transform-style: preserve-3d;
}

.socialresponsibility-principle-card-front,
.socialresponsibility-principle-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  padding: 70px;

  @media (--lt-1000) {
    padding: 40px;
  }
}

.socialresponsibility-principle-card-front {
  background-color: var(--color-white);
}

.socialresponsibility-principle-card-back {
  background-color: var(--color-white);
  transform: rotateY(180deg);
}

.socialresponsibility-principle-card-letter {
  font-size: 260px;
  line-height: 1;
  margin-bottom: 32px;

  & svg {
    fill: var(--color-blue-main);
    width: 100%;
  }
}

.socialresponsibility-principle-card-title {
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 2em;

  & svg {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    fill: var(--color-blue-main);
  }
}

.socialresponsibility-principle-content {
  width: 49%;

  @media (--lt-1400) {
    width: calc(50% - 40px);
  }

  @media (--lt-800) {
    width: 100%;
  }
}

.socialresponsibility-principle-content-title {
  margin-bottom: 0.8em;
}

#about-socialresponsibility-ultratext2 {
  padding-top: 52px;

  @media (--lt-1000) {
    padding-top: 2px;
  }
}

#about-socialresponsibility-textimage2 {
  z-index: 2;

  @media (--lt-1000) {
    margin-bottom: 0;
  }

  & .section-part-image.m-box-shadow {
    box-shadow: var(--color-gray-2) 0 20px 80px -20px;
  }

  & .section-part-text {
    @media (--lt-1200) {
      background-color: var(--color-white);
      padding: 120px 32px 64px;
      margin-top: -70px;
      width: calc(100% + 64px);
      max-width: calc(805px + 64px);
    }

    @media (--lt-1000) {
      max-width: none;
      margin-left: -32px;
      margin-right: -32px;
    }
  }
}

#about-socialresponsibility-claim {
  padding: 50px 0 200px;
}

#about-socialresponsibility-bigcards {
  padding: 160px 0;

  @media (--lt-1000) {
    padding: 100px 0;
  }
}

.socialresponsibility-bigcards-filter-container {
  position: sticky;
  top: -1px;
  padding-top: calc(54px + 1px);
  z-index: 100;
  background-color: var(--color-gray-2);
  transition: all 0.15s ease-in-out;

  @media (--lt-1100) {
    position: relative;
    background-color: transparent;
  }

  &.is-sticky {
    padding-top: calc(60px + 1px);
    padding-bottom: 10px;

    & .socialresponsibility-bigcards-filter-title {
      margin-bottom: 0;
    }

    & .socialresponsibility-bigcards-filter {
      padding-top: 10px;
    }

    & .socialresponsibility-bigcards-filter-button {
      padding: 0.5em 2em;
      margin: 0 10px;

      @media (--lt-1100) {
        margin: 0 auto 10px;
      }
    }
  }
}

.socialresponsibility-bigcards-filter-title {
  transition: all 0.15s ease-in-out;
}

.socialresponsibility-bigcards-filter {
  justify-content: center;

  @media (--lt-1100) {
    flex-direction: column;
  }
}

.socialresponsibility-bigcards-filter-button {
  margin: 0 16px;
  min-width: 200px;

  @media (--lt-1100) {
    margin: 0 auto 10px;
  }
}

#esg-default-bigcards {
  padding: 160px 0;
}

.socialresponsibility-bigcards-grid {
  margin-top: 160px;
  grid-template-columns: repeat(3, minmax(calc(33.33333% - 32px), 1fr));
  grid-template-rows: auto;

  @media (--lt-1000) {
    margin-top: 80px;
  }

  @media (--lt-1300) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-800) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

#about-socialresponsibility-gallery {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

#about-socialresponsibility-joinus {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

#about-socialresponsibility-download {
  margin-top: 200px;

  @media (--lt-1200) {
    margin-top: 160px;
  }

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

.esg-docs {
  padding: 130px 0;

  .esg-docs-row {
    display: flex;
    justify-content:  space-between;

    & a {
      @media (--lt-1200) {
        margin-bottom: 35px;
      }
    }

    @media (--lt-1200) {
      flex-flow: column;
    }
  }
}