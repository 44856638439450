.section-joinus {
  contain: content;
  padding: 50px 56px 100px;

  @media (--lt-1000) {
    padding: 50px 32px 100px;
  }

  & .container {
    padding: 80px 120px;

    @media (--lt-1000) {
      padding: 40px 60px;
    }

    @media (--lt-480) {
      padding: 40px 32px;
    }

    & > * + * {
      margin: 40px 0 0;
    }
  }
}
