/*********************** FONTS ***********************/

/********** Gotham **********/

/* Regular */
@font-face {
  font-family: "Gotham";
  src: url("etc/fonts/Gotham-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Regular Italic */
@font-face {
  font-family: "Gotham";
  src: url("etc/fonts/Gotham-BookItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Medium */
@font-face {
  font-family: "Gotham";
  src: url("etc/fonts/Gotham-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: "Gotham";
  src: url("etc/fonts/Gotham-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Bold Italic */
@font-face {
  font-family: "Gotham";
  src: url("etc/fonts/Gotham-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/********** Trump Gothic Pro **********/

@font-face {
  font-family: "Trump";
  src: url("etc/fonts/TrumpGothicPro.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trump";
  src: url("etc/fonts/TrumpGothicPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/********** Hatton **********/

@font-face {
  font-family: "Hatton";
  src: url("etc/fonts/Hatton-Medium.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/********** PF **********/

@font-face {
  font-family: "christmas";
  src: url("etc/fonts/story-of-christmas.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}