body.page-article-detail {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#article-detail-intro {
  margin-bottom: -160px;
  contain: none;
}

#article-detail-body {
  background-color: var(--color-white);
  padding-top: 240px;
  padding-bottom: 120px;
  contain: none;

  @media (--lt-480) {
    padding-top: 200px;
  }

  & p {
    font-size: 18px;
    line-height: 2.1;

    @media (--lt-1000) {
      font-size: 15px;
      line-height: 1.75;
    }
  }

  & blockquote p {
    font-size: 20px;
    font-weight: 700;
    line-height: 2;
    letter-spacing: normal;
    padding: 0 120px;
    margin: 80px 0;
    position: relative;

    @media (--lt-640) {
      font-size: 18px;
      padding: 0 60px;
    }

    @media (--lt-480) {
      font-size: 15px;
      padding: 0 40px;
      margin: 40px 0;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 68px;
      height: 48px;
      background-image: url("images/quote.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      @media (--lt-640) {
        width: 34px;
        height: 24px;
      }

      @media (--lt-480) {
        width: 24px;
        height: 17px;
      }
    }

    &:before {
      left: 0;
      bottom: 0;
      transform: translateY(50%);
    }

    &:after {
      right: 0;
      top: 0;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  & figure.media {
    margin: 2em 0;
  }
}

.article-detail-datetime {
  display: block;
  margin-bottom: 32px;
}

.article-detail-title {
  margin-bottom: 1em;
}

.article-detail-perex {
  &,
  & p {
    font-size: 20px;
    font-weight: 700;
    line-height: 2;
    @media (--lt-1000) {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}

.article-detail-image {
  margin-top: 80px;
  text-align: center;

  @media (--lt-480) {
    margin-top: 40px;
  }
}

#article-detail-gallery {
  background-color: var(--color-white);
}

#article-detail-controls {
  background-color: var(--color-white);
  padding: 100px 0;
}

.article-detail-controls {
  justify-content: space-between;
}

.article-detail-controls-prev {
  margin: 0 auto 0 0;

  @media (--lt-1000) {
    margin: 1em auto;
  }
}

.article-detail-controls-next {
  margin: 0 0 0 auto;

  @media (--lt-1000) {
    margin: 0 auto;
  }
}
