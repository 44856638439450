#expansion {
  .animate {
    @apply relative;
    transition: 1200ms ease all;

    &:not(.show) {
      @apply lg:opacity-0 lg:top-[100px];
    }
    &.show {
      @apply lg:opacity-100 lg:top-0;
    }
  }
}