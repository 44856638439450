.social-icons {
  contain: content;
}

.social-icons-item {
  display: block;
  position: relative;
  width: 25px;
  height: 25px;
  background-color: var(--color-black);
  transition: background-color 0.1s ease-in-out;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    background-color: var(--color-blue-main);
  }

  & svg {
    display: block;
    fill: var(--color-white);
  }
}

.social-icons-item + .social-icons-item {
  margin-left: 8px;
}

.icon-instagram {
  width: 12px;
  height: 12px;
}

.icon-facebook {
  width: 12px;
  height: 12px;
}
