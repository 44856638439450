@import "../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

.frm-fondForm-form {
  display: flex;
  flex-wrap: wrap;

  .w-full {
    width: 100%;
    flex-shrink: 0;
  }

  .iti__flag {
    background-image: url("./images/flags.png");
  }

  @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url("./images/flags@2x.png");
    }
  }

  input[type="tel"] {
    width: 78%;
  }

  .form-field-phone {
    display: flex;
    justify-content: space-between;
  }

  .iti {
    width: 30%;
  }

  .iti-input {
    text-align: left;
    width: 100%;
    height: 100%;
    cursor: default;
  }

  .iti__country {
    background: none;
  }

  @media (max-width: 800px) {
    .iti {
      width: 33%;
    }
    input[type="tel"] {
      width: 65%;
    }
  }

  .form-group {
    display: flex;
    gap: 2%;
  }
  .group-name {

    input {
      width: 49%;
    }
  }

  .input-tel {
    width: 100%;
  }

}