/*********************** VARIABLES ***********************/

:root {
  /* Colors */
  --color-white: #ffffff;
  --color-black: #000000;

  --color-blue-main: #40bfef;
  --color-blue-light: #b0e9ff;
  --color-blue-ultralight: #edf4f7;
  --color-blue-darker: #25abdd;

  --color-gray-1: #2e2e2e;
  --color-gray-2: #36383a;
  --color-gray-3: #979b9f;
  --color-gray-shadow: #b7bec8;
  --color-gray-blue: #b7ccd5;

  --color-green: #8ebe3f;
  --color-yellow-visu: #f1ce0d;

  --color-red: #f00000;

  --color-orange: #FA7308;

  --color-green-ewaste: #95BB20;
}

/* Basic responsive breakpoints */
@custom-media --lt-360 (max-width: 360px);
@custom-media --lt-480 (max-width: 480px);
@custom-media --lt-560 (max-width: 560px);
@custom-media --lt-640 (max-width: 640px);
@custom-media --lt-700 (max-width: 700px);
@custom-media --lt-800 (max-width: 800px);
@custom-media --lt-900 (max-width: 900px);
@custom-media --lt-1000 (max-width: 1000px);
@custom-media --lt-1100 (max-width: 1100px);
@custom-media --lt-1200 (max-width: 1200px);
@custom-media --lt-1300 (max-width: 1300px);
@custom-media --lt-1400 (max-width: 1400px);
@custom-media --lt-1500 (max-width: 1500px);
@custom-media --lt-1600 (max-width: 1600px);
@custom-media --lt-1850 (max-width: 1850px);

@custom-media --gt-1000 (min-width: 1000px);
@custom-media --gt-1400 (min-width: 1400px);
@custom-media --gt-1600 (min-width: 1600px);

@custom-media --portrait (max-aspect-ratio: 1/1);
