.a-pulse-blue {
  background: rgba(64, 191, 239, 1);
  box-shadow: 0 0 0 0 rgba(64, 191, 239, 1);
  animation: pulse-blue 3s infinite;
  border-radius: 50%;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(64, 191, 239, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(64, 191, 239, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(64, 191, 239, 0);
  }
}
