.esg-card {
  &.is-active {
    display: flex;
  }
}

.esg-card-image-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 65%;

  &:hover {
    & .esg-card-image-overlay {
      opacity: 1;
    }
  }
}

.esg-card-image {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.esg-card-content {
  padding: 40px;
  flex-grow: 1;
  gap: 1rem;

  @media (--lt-480) {
    padding: 40px 20px;
  }

  & > * {
    margin: 0;
  }

  & > p {
    margin-bottom: 3em;
  }

  & > .btn {
    margin-top: auto;
    margin-right: auto;
  }
}

.esg-card-image-overlay-vector-wrap {
  background-color: var(--color-blue-main);
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-bottom: 32px;
}

.esg-card-image-overlay-vector {
  fill: var(--color-white);
  max-width: 60%;
  max-height: 50%;
}

.esg-card-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(46, 46, 46, 0.7);
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
