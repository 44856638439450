body.page-about-brnoairport {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#about-brnoairport-bigimage {
  margin: 200px 0;

  @media (--lt-1200) {
    margin: 100px 0;
  }
}
