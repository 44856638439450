.two-col-text {
  padding: 160px 0;

  @media (--lt-1000) {
    padding: 60px 0;
  }
  & .l-grid-gap {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));

    @media (--lt-640) {
      grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
    }

    & > * {
      margin: 0;
    }
  }
}
