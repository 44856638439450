.image-comparison {
  position: relative;
  --thumb-width: 56px;
  --thumb-pulse-width: 64px;
  height: 100%;
}

.image-comparison img:first-of-type {
  clip-path: inset(0 calc(100% - var(--fraction, 50%)) 0 0);
}

.image-comparison img:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  clip-path: inset(0 0 0 var(--fraction, 50%));
}

.image-comparison input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  appearance: none;
  width: calc(100% + var(--thumb-width));
  margin-left: calc(-1 * var(--thumb-width) / 2);
  margin-right: calc(-1 * var(--thumb-width) / 2);
  background: transparent;
  cursor: ew-resize;
}

.image-comparison input:focus {
  outline: none;
}

/* Chrome */
input::-webkit-slider-thumb {
  appearance: none;
  height: var(--thumb-width);
  width: var(--thumb-width);
  border-radius: 50%;
  border-color: transparent;
  border-style: none;
  background: rgba(255, 255, 255, 0.9);
  cursor: ew-resize;
  background-image: url("images/pin_cluster_acc_symbol.svg");
  background-size: 70% 70%;
  background-repeat: no-repeat;
  background-position: center center;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  outline: 1px solid rgba(255, 255, 255, 0.7);
  outline-offset: 10px;
}

.image-comparison input:hover::-webkit-slider-thumb {
  animation: none;
  outline: none;
}

/* All the same stuff for Firefox */
input::-moz-range-thumb {
  height: var(--thumb-width);
  width: var(--thumb-width);
  border-radius: 50%;
  border-color: transparent;
  border-style: none;
  background: rgba(255, 255, 255, 0.9);
  cursor: ew-resize;
  background-image: url("images/pin_cluster_acc_symbol.svg");
  background-size: 70% 70%;
  background-repeat: no-repeat;
  background-position: center center;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  outline: 1px solid rgba(255, 255, 255, 0.7);
  outline-offset: 10px;
}

.image-comparison input:hover::-moz-range-thumb {
  animation: none;
  outline: none;
}

/* animation keyframes */
@keyframes pulse {
  0% {
    height: var(--thumb-width);
    width: var(--thumb-width);
  }
  20% {
    height: var(--thumb-pulse-width);
    width: var(--thumb-pulse-width);
  }
  100% {
    height: var(--thumb-width);
    width: var(--thumb-width);
  }
}