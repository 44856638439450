.text-shadow {
  text-shadow: 0 0 3px #000000bd;
}

body.page-home {

  header.headroom--top .page-header-nav-item-link {
    @media (--gt-1400) {
      @extend .text-shadow;
    }
  }

  .section-title {
    @apply font-trump text-[34px] lg:text-[70px] font-700 leading-[44px] md:leading-[126%] tracking-[2px] uppercase pb-32px md:pb-40px;
  }

  .section-subtitle {
    @apply font-gotham text-[14px] lg:text-[18px] font-600 leading-[20px] lg:leading-[24px] tracking-[-0.5px] uppercase;
  }

  .section-subtitle-secondary {
    @apply font-gotham text-[24px] font-600 leading-[24px] tracking-[-0.5px] uppercase;
  }

  h2 {
    &.typo-size-label {
      @apply font-gotham font-600 text-[18px] leading-[26px];
    }
  }

  .box-container {

  }

  & .page-header.headroom--top {
    & .page-header-logo,
    & .page-header-nav,
    & .page-header-nav-locales {
      color: var(--color-white);
    }
  }

  & .page-header.headroom--top.is-active {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-black);
    }
  }

  & .section-side:not(.sticky-parallax) {
    position: relative;

    & > .container {
      padding-top: 100px;

      @media (--lt-1000) {
        padding-top: 40px;
      }
    }
  }

  & .section-side.sticky-parallax, .sticky-parallax .section-side {
      & > .container {
        padding-top: 200px;
      }
    }

  & .section-side {
    padding: 0;

    & > .container {
      align-self: center;

      @media (--lt-1000) {
        padding-top: 40px;
      }
    }
  }

  .bee-container {
    z-index: 300;

    .bee-wrap {
      transform-origin: -50% -50%;
    }

    .bee-1 {
      transform: rotate(-45deg);
      offset-path: path("m20.67,96.44c22.09,34.95,57.04,47.72,75,54,38.35,13.41,69.04,9.45,119,3,53.88-6.95,95.18-12.85,128-45,7.54-7.39,34.08-33.38,26-52-8.39-19.31-51.23-24.18-61-10-7.57,10.99,4.73,33.39,14,45,23.35,29.25,63.83,35.67,200,35,123.93-.61,132.14-6.13,172,4,49.72,12.64,60.48,27.21,111,33,39.8,4.56,98.07,11.24,128-25,23.73-28.74,27.12-81.42,3-108-38.06-41.94-137.78-11.36-227,16-71.75,22-66.34,30.79-104,35-96.73,10.81-122.42-48.3-209-37-75.52,9.85-82.33,58.25-140,50-53.12-7.6-58.09-50.21-138-78C98.33,9.72,30.06-14.02,7.67,13.44c-17.14,21.02.19,62.74,13,83Z");

      &.animate {
        animation: beeMove1 10s infinite linear;
      }
    }

    .bee-2 {
      //background-color: black;
      transform: scale(.8) rotate(-45deg);
      offset-path: path("m176.91,103.18c6.83-34.92-19.05-62.69-24-68C119.34-.84,56.76-9.32,38.91,12.18c-12.75,15.36,7.37,33.91-3,71C25.28,121.22-3.26,128.08.91,144.18c6.53,25.16,84.98,41.82,137,9,8.75-5.52,33.33-21.03,39-50Z");

      &.animate {
        animation: beeMove2 5s infinite linear;
      }
    }
  }

  @keyframes beeMove1 {
    0% {
      offset-distance: 0%;
    }
    20%, 30% {
      offset-distance: 23%;
    }
    50%, 60% {
      offset-distance: 60%;
    }
    100% {
      offset-distance: 100%;
    }
  }
  @keyframes beeMove2 {
    0% {
      offset-distance: 0%;
    }
    30%, 70% {
      offset-distance: 55%;
    }
    80%, 90% {
      offset-distance: 85%;
    }
    100% {
      offset-distance: 100%;
    }
  }

  .page-header-nav-item {
  }
}

#hp-intro {
  position: relative;
  overflow: hidden;
  z-index: 50;

  @media (--lt-1000) {
  }

  & .background-line {
    z-index: 1;
  }

  @extend .text-shadow;
}



.hp-intro-stats {
}

.hp-intro-stats-item {
  //padding: 50px 0 25px 0;
  width: 25%;

  @media (--lt-1400) {
    padding: 35px 0;
  }

  @media (--lt-1000) {
    padding: 20px 0;
  }

  @media (--lt-640) {
    width: 50%;
  }
}

.hp-intro-stats-unit {
  @apply font-trump;

  @media (--lt-1000) {
    font-size: 18px;
  }
}

#hp-mission-text {
  padding: 160px 0;
  z-index: 49;

  @media (--lt-640) {
    padding: 80px 0;
  }
}

.hp-mission-text-title {
  margin-bottom: 32px;
}

#hp-sunset {
  margin: 0;
  position: relative;
  z-index: 48;
  padding: 0;
  padding-bottom: 540px;

  @media (--lt-1000) {
    padding-bottom: 300px;
  }

  & .hp-sunset-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 828px;
    width: 100%;
    z-index: -3;
    object-fit: cover;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 45%;
    width: 100%;
    z-index: -2;
    background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(46, 46, 46, 0) 100%);
  }
}

#hp-infrastructure {
  z-index: 47;

  & h1 {
    margin-bottom: 32px;
  }

  & p {
    margin: 0;
  }

  & a {
    margin-top: 56px;
  }

  & > .container {
    @media (--lt-1000) {
      margin-top: 50px;
    }
  }

  & .section-side > .container-image img {
    top: initial;
    left: initial;
    transform: initial;
  }
}

.hp-infrastructure-map {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#hp-work {

  @media (--lt-640) {
    padding: 80px 0 0;
  }
}

.hp-work-title {
  margin-bottom: 32px;
}

#hp-ultratext2 {
  margin: 0;
}

#hp-tenants-bubbles {
  z-index: 44;

  & .container {
    height: 100%;
  }
}

#hp-fond {
  z-index: 43;

  @media (--lt-1000) {
    padding: 50px 0 100px;
    & > .container,
    & > .container-image {
      width: 100%;
    }

    & > .container-image {
      position: relative;
      padding-top: calc(100% + 32px);
    }
  }

  & .newsletter-form {
    & input[type="email"] {
      background-color: var(--color-blue-ultralight);
    }

    .newsletter-form-label-email {
      @media (--lt-480) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }

  & .hp-fond-button {
    margin-top: 56px;
  }

  & .container:last-child {
    @media (--gt-1000) {
      padding: 200px 120px 50px;
      text-align: center;
    }
  }
}

.hp-fond-newsletter {
  padding: 60px 120px;
  margin: 0 -100px 32px;

  @media (--lt-1500) {
    padding: 40px 60px;
    margin: 0 0 32px;
  }

  @media (--lt-1000) {
    padding: 30px;
  }
}

#hp-location {
  z-index: 42;

  @media (--lt-1000) {
    padding-top: 50px;

    & > .container,
    & > .container-image {
      width: 100%;
    }
  }

  & ul {
    margin: 40px 0;
  }

  & .hp-location-button {
    margin-top: 56px;
  }
}

.hp-location-animation {
  width: 100%;
  height: 100%;
  position: relative;

  & > svg {
    @media (--gt-1000) {
      width: auto !important;
      height: 100% !important;
    }
  }
}

#hp-parks {
  padding-top: 200px;
  padding-bottom: 100px;
  z-index: 41;

  @media (--lt-1000) {
    padding-top: 100px;
  }

  & .park-simple-card:hover {
    & .park-simple-card-inner {
      transform: none;
    }
  }
}

.hp-parks-wrapper {
  @media (--lt-1000) {
    grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));
  }

  @media (--lt-640) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-480) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

#hp-ultratext3 {
  margin: -80px 0 0;
  z-index: 40;
}

#hp-sustainability {
  margin-bottom: 200px;
  z-index: 39;

  @media (--lt-1400) {
    margin-bottom: 100px;
  }

  @media (--lt-640) {
    margin-bottom: 50px;
  }

  & ul {
    margin-top: 32px;
  }
}

#hp-news {
  padding-bottom: 200px;
  z-index: 38;
  overflow: hidden;

  @media (--lt-1400) {
    padding-bottom: 100px;
  }

  @media (--lt-640) {
    padding-bottom: 50px;
  }
}

#hp-airport {
  margin-bottom: 200px;
  z-index: 37;

  @media (--lt-1200) {
    margin-bottom: 0;
  }

  @media (--lt-1000) {
    //margin-bottom: 100px;
  }
}

#hp-polygon {
  z-index: 36;
}

.page-footer {
  @apply max1200:mt-0;
}