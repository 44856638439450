body.page-about-team {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

.team-departement {
  contain: layout;
}

.team-departement + .team-departement {
  margin-top: 2rem;

  @media (--lt-1000) {
    margin-top: 100px;
  }
}

.team-departement {

    &.blink:before {
      animation-name: bgBlink;
      animation-direction: normal;
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-timing-function: ease;
    }

    .title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .buttons {
      display: flex;
    }

    .team-departement-title {
        margin-bottom: 0;
    }

    .content-wrap {
        overflow: hidden;

        .content {
            padding-top: 4rem;
        }
    }

  &:not(.management) {
   position: relative;
   padding-top: 4rem;
   padding-bottom: 4rem;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: -100%;
      right: -100%;
      height: 100%;
      background: white;
    }

    /*.team-departement-title {*/
    /*  padding-top: .8em;*/
    /*}*/
  }

  &.management {
     padding-top: 100px;

      .team-departement-title {
        position: relative;
        display: inline-block;

        &:before {
           content: "";
           position: absolute;
           z-index: -1;
           left: calc((100vw - 1400px) / -2);
           top: calc(50% + 0.5em);
           transform: translateY(-50%);
           width: calc(100% + (100vw - 1400px) / 2 + 64px);
           min-width: 200px;
           height: 320px;
           background-color: var(--color-white);

          @media (--lt-1500) {
            left: -64px;
            width: calc(100% + 64px + 64px);
          }

          @media (--lt-1000) {
            left: -32px;
            width: calc(100% + 32px + 32px);
            height: 220px;
          }
        }
      }
   }

    .hidden {
        display: none;
    }
    .collapsed {

    }
    .collapse-arrow-wrap {
        display: flex;
        margin-top: 4rem;
        justify-content: end;
    }
}

.team-departement-list {
  row-gap: 80px;
  width: 100%;

  @media (--lt-1000) {
    grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));
    row-gap: 60px;
  }

  @media (--lt-800) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-480) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

@-webkit-keyframes bgBlink {
  0% { background: var(--color-blue-main); }
  100% { background: white; }
}