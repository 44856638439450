/*********************** FLASH ***********************/

.flash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--color-white);
  font-weight: 700;
  text-align: center;
  z-index: 999;
  animation-name: hideFlash;
  animation-delay: 10s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  cursor: pointer;
  contain: content;
  display: flex;
  justify-content: center;
  align-items: center;

  &.error {
    background-color: red;
  }

  &.is-hidden {
    display: none;
  }
}

.flash-message {
  background-color: var(--color-blue-main);
  padding: 20px 40px;
  max-width: 700px;
}

@keyframes hideFlash {
  0% {
    opacity: 1;
    visibility: visible;
  }

  99% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
