.dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 40px;

  &::backdrop {
    display: none;
  }
}

.dialog-content {
  background-color: var(--color-white);
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  z-index: 99;
}

.dialog[open] {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.dialog-controls {
  display: inline-block;
  position: absolute;
  z-index: 100;
  right: 40px;
  top: 40px;
}
