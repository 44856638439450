.background-line {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: var(--color-gray-1);
  z-index: -1;
  opacity: 0.3;
  mix-blend-mode: exclusion;
}

.background-line-center {
  left: 50%;
}

.background-line-left1 {
  left: calc(50% - (1400px / 2));

  @media (--lt-1500) {
    left: 56px;
  }

  @media (--lt-1000) {
    left: 32px;
  }
}

.background-line-left2 {
  left: calc(50% - (1400px / 4));

  @media (--lt-1500) {
    left: calc((100% - 2 * 56px) / 4 + 56px);
  }

  @media (--lt-1000) {
    display: none;
  }
}

.background-line-right1 {
  right: calc(50% - (1400px / 2));

  @media (--lt-1500) {
    right: 56px;
  }

  @media (--lt-1000) {
    right: 32px;
  }
}

.background-line-right2 {
  right: calc(50% - (1400px / 4));

  @media (--lt-1500) {
    right: calc((100% - 2 * 56px) / 4 + 56px);
  }

  @media (--lt-1000) {
    display: none;
  }
}
