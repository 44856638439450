.park-card {
  transition: all 0.15s ease-in-out;
  contain: content;

  @media (--lt-1000) {
    transition-duration: 0s;
  }
  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }
}

.park-card-inner {
  width: 100%;
  height: 100%;
}

.park-card-image {
  width: 240px;

  @media (--lt-640) {
    width: 100%;
  }

  & img {
    max-width: none;
    display: inline;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}

.park-card-text {
  padding: 32px;
  width: calc(100% - 240px);

  @media (--lt-640) {
    padding: 24px 32px;
    width: 100%;
  }
}

.park-card-title {
  margin-bottom: 0;
}

.park-card-tenants {
  margin-top: auto;
  grid-template-columns: repeat(4, minmax(calc(25% - 16px), 1fr));
  column-gap: 16px;
  row-gap: 16px;
  align-items: center;
}
