.card-show-more {
  align-items: center;
  text-align: center;
  position: relative;
  text-decoration: none;
  transition: transform 0.15s;

  &:hover {
    transform: scale(1.02);
  }
}

.card-show-more-inner {
  width: 100%;
  height: 100%;
  color: var(--color-white);
  background-color: var(--color-blue-main);
  justify-content: center;
  align-items: center;
}

.card-show-more-image {
  content: "";
  display: block;
  position: relative;
  margin: 0 0 32px;
  width: 150px;
  height: 150px;
  fill: var(--color-white);
}
