.page-footer {
  @apply max1200:py-20px py-[200px];
  contain: content;

  @media (--lt-1400) {
    padding: 100px 0;
  }

  @media (--lt-1000) {
    background-color: var(--color-white);
  }

  & .newsletter-form {
    max-width: 445px;
  }

  & .newsletter-form input[type="email"] {
    @media (--lt-1000) {
      background-color: var(--color-blue-ultralight);
    }
  }
}

.page-footer-part:not(:last-child) {
  margin-bottom: 30px;
}

.page-footer-part1 {
  gap: 50px;
  flex-wrap: nowrap;

  @media (--lt-1000) {
    flex-direction: column;
  }

  & > .page-footer-menu:first-of-type {
    margin-left: auto;
    @media (--lt-800) {
      margin-left: 0;
    }
  }
}

.page-footer-menu {
  & h6 {
    margin-bottom: 2em;
    @media (--lt-480) {
      margin-bottom: 1em;
    }
  }
}

.page-footer-newsletter-form {
  margin-right: auto;

  @media (--lt-800) {
    width: 100%;
    margin-right: 0;
  }
}

.page-footer-developer {
  align-items: flex-end;

  & svg {
    width: 25px;
    height: 16px;
    vertical-align: middle;
    margin-left: 1em;
    transition: fill 0.1s ease-in-out;

    @media (--lt-1000) {
      transition-duration: 0s;
    }
  }

  & a:hover svg {
    fill: var(--color-yellow-visu);
  }
}

.page-footer-legal {
  margin-right: auto;
  max-width: 50%;

  @media (--lt-800) {
    width: 100%;
    max-width: 100%;
  }
}

p .page-footer-ic {
  color: transparent;
}

.page-footer-personal-data {
  @media (--lt-800) {
    width: 100%;
    margin-top: 32px;
  }

  & button {
      padding: 0;
      text-align: left;
      background: none;
  }
}