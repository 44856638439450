body.christmas-page {
  .gift-content {

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #b7b7b7;
    }

    &::-webkit-scrollbar-thumb {
      background: #047690;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #164F73;
    }

  }
}