.button-scroll-to-top {
  position: fixed;
  z-index: 100;
  right: 30px;
  bottom: 30px;
  width: 3em;
  height: 3em;
  padding: 0;
  transition: all 0.2s ease-in-out;
  contain: layout size;

  @media (--lt-1600) {
    right: 12px;
    bottom: 12px;
  }

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    background-color: var(--color-blue-darker);
    & .button-scroll-to-top-text {
      width: auto;
      padding: 0 0 0 1em;
    }
  }

  &.headroom-scroll-btn--top {
    opacity: 0;
    visibility: hidden;
  }

  &.headroom-scroll-btn--not-top {
    opacity: 1;
    visibility: visible;
  }
}

.button-scroll-to-top-text {
  position: absolute;
  right: 3em;
  top: 0;
  width: 0;
  overflow: hidden;
  height: 100%;
  z-index: -2;
  line-height: 3em;
  background-color: var(--color-blue-main);
  color: var(--color-white);
  transition: all 0.2s ease-in-out;

  @media (--lt-1000) {
    transition-duration: 0s;
  }
}
