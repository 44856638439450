body {
  &.lease,
  &.lease-detail {
    header:not(.scroll) {
      .page-header-logo, .page-header-nav, .page-header-nav-locales {
        @apply text-white;
      }
    }

    footer {
      @apply bg-dark text-white;
    }

    .container-lg {
      @apply max-w-[theme('screens.xl')] px-24px mx-auto;
    }

    footer .social-icons-item {
      @apply bg-white;

      svg {
        @apply fill-black;
      }

      &:hover {
        @apply bg-blue;

        svg {
          @apply fill-white;
        }
      }
    }
  }
}

body.lease {
  @apply bg-dark-1;

  #main-section {
    contain: unset;
  }

  .page-title {
    span {
      @apply text-blue;
    }
    @apply text-white;
  }

  .filter-result {
    @apply text-dark-4 text-center;
    span {
      @apply text-gray-5;
    }
  }

  #lease-map {
    [role=button], button {
      cursor: default;
    }
  }
}

body.lease-detail {
  .park-title {
    @apply text-white;
  }

  .container-text-card-background::before {
    @apply content-[''] bg-white absolute top-0 lg:top-56px left-0 h-full w-full lg:w-7/10 -z-1;
  }

  #park-detail-gallery {
    @apply my-0;

    h2 {
      @apply font-trump text-42px font-700 tracking-2 text-black uppercase mb-0;
    }
  }

  .highway-data {
    p {
      @apply my-0 text-14px leading-[22px];
    }

    strong {
      @apply block md:inline;
    }
  }
}