.newsletter-form {
  contain: content;
  flex-wrap: nowrap;
  flex-direction: column;

  & button {
    max-height: 47px;
  }

  @media (--gt-1000) and (--lt-1300) {
    flex-flow: column;
  }
  
  @media (--lt-560) {
    flex-direction: column;
  }

  & > * + * {
    margin-top: 25px;
  }

  & .newsletter-form-checkboxes {
    display: flex;
    align-items: center;

    & * + * {
      margin-right: 20px;
    }
  }

  & .newsletter-form-label-email {
    flex-grow: 1;

    @media (--lt-560) {
      margin-right: 0;
    }

    @media (--gt-1000) and (--lt-1300) {
      margin-right: 0;
    }
  }

  & .newsletter-form-label-checkbox {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    display: block;

    &:hover {
      & .form-field-checkbox-mark:after {
        opacity: 0.3;
      }
    }

    & .white-checkbox::before {
      background-color: var(--color-white);
    }
  }

  & a {
    text-decoration: none;
  }

  & a:hover {
    color: var(--color-blue-main);
  }

  & input[type="email"] {
    width: 100%;
  }

  & input[type="checkbox"] {
    width: 50%;
  }
}
