.youtube-video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.youtube-video-wrapper-noaspect.youtube-video-wrapper {
  min-height: 500px;
  padding-bottom: 0px;
}

.youtube-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.youtube-video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* width: 48px;
	height: 48px; */
}

.youtube-video-play-label {
  position: absolute;
  top: calc(50% + 60px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  padding-bottom: 2px;
  /* opacity: 0; */
  /* transition: all 0.15s ease-in-out; */

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: var(--color-white);
    transition: width 0.15s ease-in-out;
  }
}

.youtube-video-curtain {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-image: url("./images/fond/video_cover.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;

  &:hover {
    & .youtube-video-play-button {
      background-color: var(--color-blue-darker);
    }

    & .youtube-video-play-label {
      opacity: 1;
      /* text-shadow: 0px 0px 5px rgba(0,0,0,0.6),
                   0px 0px 3px rgba(0,0,0,0.8);   */
    }
    & .youtube-video-play-label:before {
      width: 100%;
    }
  }
}

/* Style Video About Us - Career */
.page-about-career .youtube-video-wrapper .youtube-video-curtain {
  height: 70%;
}

.page-about-career .youtube-video-wrapper iframe {
  height: 70%;
}

.page-about-career .youtube-video-wrapper-noaspect.youtube-video-wrapper .youtube-video-curtain {
  height: 100%;
}

.page-about-career .youtube-video-wrapper-noaspect.youtube-video-wrapper iframe {
  height: 100%;
}