body.page-contact {
  & .page-header.headroom--top {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-white);
    }
  }

  & .page-header.headroom--top.is-active {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-black);
    }
  }

  & .page-footer {
    display: none;
  }
}

#contact {
  position: relative;
  background-image: url("images/contact/contact_map.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-attachment: fixed;
  background-size: 55%;

  & .social-icons {
    margin-top: 50px;
  }

  & .container {
    background-color: var(--color-white) !important;
    width: 55%;

    @media (--lt-1000) {
      width: 100%;
    }
  }

}

.contact-label-prague {
  position: absolute;
  z-index: 2;
  right: 672px;
  top: 66%;
}

.contact-label-warszawa {
  position: absolute;
  z-index: 2;
  right: 315px;
  top: 42.5%;
}

.contact-grid {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  row-gap: 50px;

  @media (--lt-1100) {
    grid-template-columns: 1fr;
  }
}
