@import "../../../../../node_modules/vanilla-cookieconsent/dist/cookieconsent.css";

#cookies-component {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 34px 34px 45px 34px;
    border-radius: 10px;
    background: white;
    box-shadow: 0 0.625em 1.875em #000;
    max-width: 670px;

    @media (--lt-800) {
        top: auto;
        bottom: 5%;
        left: 5%;
        width: 90%;
        transform: translate(0,0);
        padding: 24px;
    }

    .content {
        display: flex;
        gap: 30px;
        margin-bottom: 32px;

        @media (--lt-800) {
            flex-wrap: wrap;
            gap: 10px;
        }

        .text {
          font-size: 14px;
            //max-width: 350px;

            @media (--lt-800) {
                width: 100%;
            }

            p {
                //line-height: 16px;
            }

            a {
                font-weight: bold;
            }

            .title {
                font-size: 1.5em;
                font-weight: bold;
                padding-bottom: 1em;
            }
        }

        img {
            max-width: 210px;

            @media (--lt-800) {
                max-width: 150px;
                margin: 0 auto;
            }
        }

    }

    .buttons {
        display: flex;
        gap: 23px;
        font-weight: bold;

        @media (--lt-800) {
            flex-wrap: wrap;
            gap: 10px;
        }

        button {
            background: #e5ebef;
            padding: 1em 1.7em;
            display: inline-block;
            cursor: pointer;
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            text-align: center;
            border-radius: 4px;
            flex: 1;
            flex-grow: 1;
            font-size: 14px;
            color: #28394B;
            font-weight: bold;
            transition: background-color .25s ease!important;

            @media (--lt-800) {
                flex: auto;
                flex-grow: 1;
                width: 100%;
            }

            &:last-child {
                color: #fff;
                background: #253b48;

                &:hover {
                    background: #1d2e38;
                }
            }

            &:not(:last-child) {
                &:hover {
                    background: #d8e0e6;
                }
            }
        }
    }

}