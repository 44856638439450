#about-concern-members {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

.about-concern-members-grid {
  padding-bottom: 100px;
  grid-template-columns: repeat(3, minmax(calc(100% / 3 - 32px), 1fr));

  @media (--lt-1200) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - 32px), 1fr));
  }

  @media (--lt-640) {
    grid-template-columns: repeat(1, minmax(calc(100% / 1 - 32px), 1fr));
  }
}

.about-concern-members-card {
  contain: layout;
}

.about-concern-members-card-inner {
  width: 100%;
  height: 100%;
  padding: 32px;

  @media (--lt-640) {
    padding: 20px;
  }
}

.about-concern-members-card-title {
  width: 100%;
  margin-bottom: 16px;
}

.about-concern-members-card-text {
  width: 100%;
}

.about-concern-members-perex {
  padding-bottom: 50px;
  width: 50%;
  @media (--lt-1200) {
    width: 100%;
  }
}
