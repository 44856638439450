.fullscreen-slide {
  position: relative;
  justify-content: center;
  padding: 140px 0 180px;

  @media (--lt-1000) {
    justify-content: flex-start;
  }

  &.swiper-slide-active .fullscreen-slide-image {
    animation-play-state: running;
  }
}

.fullscreen-slide-container {
  position: relative;
  z-index: 2;
}

.fullscreen-slide-container-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 57%;

  @media (--lt-1400) {
    width: 75%;
  }

  @media (--lt-1000) {
    width: 100%;
  }
}

.fullscreen-slide-image {
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  animation-name: fullscreen-image-zoom-in;
  animation-duration: 7000ms;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

@keyframes fullscreen-image-zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
