.fond-pop-up {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  @media (--lt-560) {
    align-items: start;
  }
}

.fond-pop-up-card {
  width: 685px;
  max-width: 100%;
  background-color: white;
  padding: 80px;

  @media (--lt-700) {
    padding: 40px;
  }
  @media (--lt-560) {
    padding: 0;
  }
}

.fond-pop-up-card-blue-container {
  width: 100%;
  height: 300px;
  background-color: #edf4f7;
  display: flex;
  padding-right: 16px;
}

.fond-pop-up-card-white-container {
  background-color: var(--color-white);
  width: 100%;
  margin-top: 32px;
  @media (--lt-560) {
    padding: 20px;
  }
}

.fond-pop-up-hidden {
  display: none;
}

.fond-pop-up-card-white-container-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  @media (--lt-480) {
    flex-direction: column-reverse;
  }
}

.fond-pop-up-card-blue-container-inner {
  width: 100%;
  padding: 32px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-corner {
    width: 3px;
    border-radius: 10px;
  }

  ,
  &::-webkit-scrollbar-track {
    background: #cbe8f6;
    border-radius: 10px;
  }

  ,
  &::-webkit-scrollbar-thumb {
    background: #40c0f1;
    border-radius: 10px;
  }
}

::-webkit-scrollbar-track {
  margin-top: 35px;
  margin-bottom: 35px;
}

.btn.fond-pop-up-closer {
  @media (--lt-560) {
    margin-bottom: 32px;
  }
}
