.download-section-heading {
  margin-bottom: 60px;

  @media (--lt-800) {
    margin-bottom: 30px;
  }
}

.download-section-list {
  display: grid;
  grid-template-columns: 1fr;
}

.download-section-list-item {
  border-top: 1px solid var(--color-black);
  padding: 16px 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: center;
  gap: 40px;
  transition: all 200ms ease-in-out;

  @media (--lt-800) {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &:last-child {
    border-bottom: 1px solid var(--color-black);
  }

  & h3 {
    margin: 0;
  }
}

[data-compact="false"] {
  & .download-section-list-item-name {
    cursor: zoom-in;
  }
}

.download-section-list-item-name {
  display: flex;
  gap: 40px;
  align-items: center;

  &:hover {
    & .download-section-list-item-thumbnail-overlay {
      opacity: 1;
    }
  }
}

.download-section-list-item-thumbnail {
  box-shadow: var(--color-gray-3) 0 0 8px 0;
  position: relative;
}

.download-section-list-item-thumbnail-img {
  position: relative;
  z-index: 1;
}

.download-section-list-item-thumbnail-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  & svg {
    fill: var(--color-black);
    width: 32px;
    height: 32px;
  }
}

.download-section-list-item-button {
  display: flex;
  gap: 24px;
  align-items: center;
  text-decoration: none;
  justify-content: end;

  @media (--lt-800) {
    justify-content: start;
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover .btn-square-arrow {
    background-color: var(--color-blue-darker);
  }

  & .btn-square-arrow {
    flex-shrink: 0;
  }
}
