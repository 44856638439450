.article-card {
  transition: transform 0.15s ease-in-out;
  text-decoration: none;
  contain: layout;

  @media (--lt-1000) {
    transition-duration: 0s;
  }

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }
}

.article-card-inner {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
}

.article-card-text {
  padding: 40px;

  @media (--lt-1400) {
    padding: 30px;
  }

  @media (--lt-480) {
    padding: 20px 16px;
  }

  & time {
    @media (--lt-480) {
      font-size: 11px;
      font-weight: 700;
      line-height: 1.75;
    }
  }
}

.article-card-time {
  margin-bottom: 16px;
  display: block;
}

.article-card-image {
  & img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
