.person-detail {
  display: flex;
  flex-wrap: wrap;
}

.person-detail-image {
  width: calc(50% - 96px);
  margin-right: auto;
  padding-top: 48px;
  align-self: flex-end;
  text-align: center;

  @media (--lt-1000) {
    width: 100%;
    order: 1;
    padding-top: 0;
  }

  & img {
    max-height: 460px;
  }
}

.person-detail-content {
  width: 50%;
  padding: 48px 48px 48px 0;

  @media (--lt-1000) {
    width: 100%;
    padding: 48px;
  }

  & > * {
    margin: 0;
  }
}

.person-detail-name {
  font-weight: 200;
  font-size: 18px;
}

.person-detail-quote {
  margin: 24px 64px 24px 0;
  font-size: 15px;
  line-height: 1.8;
  font-weight: 600;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}
