.building-card {
  perspective: 1000px;
  contain: layout;

  &:hover,
  &.building-card-hover {
    & .building-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.building-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 320px;
  transition: transform 0.4s linear;
  transform-style: preserve-3d;
}

.building-card-frontside,
.building-card-backside {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden; /* Safari */
}

.building-card-frontside {
  position: relative;
  background-color: var(--color-white);
}

.building-card-backside {
  position: absolute;
  transform: rotateY(180deg);
  padding: 24px 26px;
  color: var(--color-white);
}

.building-card-text {
  padding: 24px 26px;
}

.building-card-text-title {
  margin-bottom: 0;
}

.building-card-text-area {
  margin-bottom: 50px;
}

.building-card-tenants {
  column-gap: 16px;
  row-gap: 16px;
  align-items: center;
}

/* BACKSIDE */
.building-card-backside-title {
  margin-bottom: 0;
}

.building-card-backside-area {
  margin-bottom: 1em;
}

.building-card-backside-parameters {
  margin-top: auto;
  color: var(--color-white);

  & tr td:first-child {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    text-align: left;
  }
  & tr td:last-child {
    text-align: right;
  }

  & tr + tr {
    border-top: 1px solid var(--color-blue-ultralight);
  }
}

.building-plan-hover, .building-plan-hover * {
  fill: var(--color-orange) !important;
}

.building-plan-completed-hover, .building-plan-completed-hover * {
  fill: var(--color-gray-1) !important;
}