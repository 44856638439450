@layer utilities {
  .page-header-nav {
    .page-header-nav-item-link {
      @apply select-none max1400:px-0 px-16px py-8px;

      &:before {
        @apply max1400:-left-8px left-6px;
      }
    }

    .page-header-nav-item-link,
    .subMenu-item-link {
      &:before {
        background-image: url("images/arrow_blue_right.svg");
      }
    }

    .nav-item-rectangle .page-header-nav-item-link {
      @apply px-20px;

      &:before {
        @apply left-10px text-white;

        background-image: url("images/arrow_white_right.svg");
      }
    }

    .page-header-nav-item-arrow .page-header-nav-item-link {
      @apply min1400:pr-32px;
    }

    .page-header-nav-submenu {
      @apply min-w-[250px] pt-6px relative min1400:absolute;

      .subMenu-item-link {
        @apply max1400:pl-0 px-12px py-8px;

        &:before {
          @apply max1400:-left-8px left-4px;
        }
      }
    }
  }

  .page-header-nav-locales {
    @apply max1720:text-[80%];

    & .page-header-nav-item[data-type="locales"] {
      &:hover {
        @apply text-white;
      }

      & .page-header-nav-submenu-toggle {
        @media (--lt-1400) {
          display: block;
        }
      }

      &.page-header-nav-item-arrow .page-header-nav-item-link {
        @apply pr-32px select-none px-16px py-8px;

        &:before {
          @apply left-6px;
        }
      }

      .subMenu-item-link {
        @apply px-12px text-center;

        &:before {
          @apply left-14px top-[50%];
          @apply bg-[url('images/arrow_white_right.svg')];
        }
      }

      .page-header-nav-item-link {
        &:before {
        }
      }
    }

    .page-header-nav-submenu {
      @apply min-w-[250px] pt-6px text-white absolute;

      .subMenu-item-link {
        @apply px-12px py-8px;

        &:before {
          @apply left-4px;
        }
      }
    }

  }

  .page-header {
    position: fixed;
    z-index: 333;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0%);
    flex-direction: row;
    padding: 40px 56px 0;
    background-color: transparent;
    transition: all 0.15s ease-in-out;
    contain: layout;

    @media (--lt-1400) {
      padding: 24px 56px 0;
    }
    @media (--lt-1000) {
      padding: 24px 16px 0;
    }

    &.is-active {
      background-color: var(--color-white);
    }

    & > .container {
      max-width: 1920px;
      align-items: center;

      @media (--lt-1400) {
        padding: 0;
      }
    }

    .page-header-mobile-toggle {
      @apply text-white justify-center items-center gap-8px;
      display: none;
      position: relative;
      z-index: 5;
      cursor: pointer;
      background-color: var(--color-blue-main);
      height: 35px;
      padding: 10px 13px;
      justify-content: space-between;
      align-items: center;
      border: none;

      @media (--lt-1400) {
        display: flex;
        flex-wrap: wrap;
        margin-left: 16px;
      }

      @media (--lt-1000) {
        margin-left: 10px;
      }
    }

    .page-header.is-active .page-header-mobile-toggle {
      & .page-header-mobile-toggle-line:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
        left: 50%;
        top: 50%;
      }
      & .page-header-mobile-toggle-line:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
        left: 50%;
        top: 50%;
      }
    }

    .page-header-mobile-toggle-text {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 1px;

      @media (--lt-640) {
        display: none;
      }
    }

    .page-header-mobile-toggle-hamburger {
      width: 10px;
      height: 6px;
      position: relative;
    }

    .page-header-mobile-toggle-line {
      position: absolute;
      background-color: var(--color-white);
      width: 10px;
      height: 1px;
      transition: all 0.15s ease-in-out;

      &:nth-of-type(1) {
        top: 0;
        left: 0;
      }

      &:nth-of-type(2) {
        bottom: 0;
        left: 0;
      }
    }
  }

  .headroom {
    will-change: transform;
    transition: transform 200ms linear, padding 200ms linear, background-color 200ms linear;
  }

  .headroom--top {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-black);
    }
  }

  .headroom--top.is-active {
    & .page-header-logo,
    & .page-header-nav {
      color: var(--color-black) !important;
    }
  }

  .headroom--top,
  .headroom--not-top {
    & .page-header-nav-item:not([data-type="locales"]) .page-header-nav-submenu {
      background-color: var(--color-white);
      color: var(--color-black);
    }
  }

  .headroom--not-top {
    background-color: var(--color-white);
    padding: 6px 56px;

    @media (--lt-1000) {
      padding: 10px 32px;
    }

    & .page-header-nav-item:not([data-type="locales"]):hover {
      & .page-header-nav-submenu,
      & > .page-header-nav-submenu {
        background-color: var(--color-white);
        color: var(--color-black);
      }
    }

    & .page-header-logo {
      color: var(--color-black);
      padding: 6px 16px 6px 0;

      @media (--lt-1000) {
        padding: 0;
        height: 35px;
      }
    }

    & .page-header-logo-fond {
      color: var(--color-white);
      padding: 6px 16px 6px 0;

      @media (--lt-1000) {
        padding: 0;
      }
    }
  }

  .nav-item-rectangle {
    display: flex;
    align-items: center;
    color: var(--color-white);
    @apply font-700 leading-none uppercase;

    .page-header-nav-item-link {
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &.is-active {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  .blue-rectangle {
    background-color: var(--color-blue-main);
  }

  .orange-rectangle {
    @apply bg-orange;
  }

  .nav-item-rectangle {
    @media (--lt-1400) {
      width: fit-content;
      &:hover {
        padding-left: 10px;
        transition: padding-left 0.3s ease-in-out;
      }
      @apply my-1px;
    }
  }

  .blue-rectangle {
    background-color: var(--color-blue-main);
  }

  .headroom--not-top.is-active {
    & .page-header-logo {
      color: var(--color-black);
    }
    & .page-header-logo-fond {
      color: var(--color-white);
    }
  }

  .page-header-logo {
    width: 180px;
    height: 42px;
    margin-right: auto;
    display: block;
    position: relative;
    z-index: 5;
    transition: all 0.2s ease-in-out;

    @media (--lt-1000) {
      width: 175px;
      /*height: 24px;*/
    }

    & svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .page-header-logo-fond {
    width: 180px;
    height: 42px;
    margin-right: auto;
    display: block;
    position: relative;
    z-index: 5;
    transition: all 0.2s ease-in-out;

    @media (--lt-1000) {
      width: 102px;
      height: 24px;
    }

    & svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .page-header-nav {
    display: flex;
    flex-direction: row;
    //color: var(--color-black);
    contain: layout;
    @apply max1720:text-[80%];

    @media (--lt-1400) {
      flex-direction: column;
      position: absolute;
      width: 100vw;
      height: calc(100vh - 52px);
      left: 100%;
      top: 52px;
      transition: left 0.15s ease-in-out;
      background-color: var(--color-white);
      padding: 32px 56px;
      overflow-y: auto;
      contain: strict;
    }

    @media (--lt-1000) {
      padding: 32px;
    }

    &:hover {
      & .page-header-nav-item {
        opacity: 0.5;
      }
      & .page-header-nav-item-link:before {
        opacity: 0;
      }
      & .page-header-nav-item-link:hover:before {
        opacity: 1;
      }
    }

    & .page-header-nav-item:hover {
      opacity: 1;
    }
  }

  .page-header.is-active .page-header-nav {
    left: 0;
  }

  .page-header-nav-item {
    position: relative;

    &:hover {
      & .page-header-nav-submenu-toggle {
        transform: translate(-50%, -50%) rotate(-180deg);
      }

      & > .page-header-nav-submenu {
        @apply min1400:flex
      }
    }
  }

  .page-header-nav-item-link,
  .subMenu-item-link {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      width: 5px;
      height: 8px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      opacity: 0;

      @media (--lt-1400) {
        left: -0.5em;
      }
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &.is-active {
      &:before {
        opacity: 1;
      }
    }
  }

  .subMenu-item-link {
    @media (--lt-1400) {
      font-size: 13px;
      line-height: 1.6;
      font-weight: 400;
    }
  }

  .page-header-nav-item-link {
    @media (--lt-1400) {
      font-family: "Trump";
      font-size: 24px;
      line-height: 1.2;
    }
  }

  .subMenu-item-link {
    @media (--lt-1400) {
      font-size: 13px;
      line-height: 1.6;
      font-weight: 400;
    }
  }

  .page-header-nav-submenu {
    display: none;
    flex-wrap: wrap;
    flex-direction: column;
    left: 0;

    &:hover, &.is-visible {
      display: flex;
    }
  }

  .page-header-nav-item-arrow {
    position: relative;
  }

  .page-header-nav-submenu-toggle {
    display: inline-block;
    color: inherit;
    position: absolute;
    top: 48%;
    right: 0.5em;
    transform: translate(-50%, -50%);
    transition: transform 0.1s linear;

    @media (--lt-1400) {
      display: none;
    }

    & svg {
      display: block;
      fill: currentColor;
      width: 8px;
      height: 5px;
    }
  }

  #logo {
    --primary-color: currentColor;
  }

  .headroom--not-top #logo {
    --primary-color: transparent;
  }

  .page-header-nav-item[data-type="locales"] {
    &:hover {
      background-color: var(--color-blue-main);
    }

    & .page-header-nav-submenu {
      background-color: var(--color-blue-main);
      min-width: 100%;

    }

    & .page-header-nav-item-link.is-active:before {
      opacity: 1;
    }

    & .subMenu-item-link:before {
      left: 0.35em;
    }

    & .locale-short {
      display: inline;
    }
  }
}
