.park-simple-card {
  text-decoration: none;
  color: var(--color-black);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  perspective: 1000px;
  contain: layout;

  &:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  &:hover .park-simple-card-inner {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

/* ////////////////////////// */
.park-simple-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 320px;
  -webkit-transition: transform 0.4s linear;
  transition: transform 0.4s linear;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.park-simple-card-frontside,
.park-simple-card-backside {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: var(--color-white);
}

.park-simple-card-frontside {
  position: relative;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.park-simple-card-backside {
  position: absolute;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  padding: 32px 40px;

  @media (--lt-1400) {
    padding: 30px;
  }

  & h3,
  & h6 {
    margin-bottom: 0;
  }
}
/* ////////////////////////// */

.park-simple-card-text {
  padding: 32px 40px;

  @media (--lt-1400) {
    padding: 30px;
  }

  @media (--lt-480) {
    padding: 20px;
  }

  & h3 {
    margin-bottom: 0;
  }
}

.park-simple-card-tenants {
  margin-top: 16px;
  grid-template-columns: repeat(3, minmax(calc(33.33% - 16px), 1fr));
  column-gap: 16px;
  row-gap: 16px;
  align-items: center;
}

.park-simple-card-tenants-item {
  & img {
    max-height: 40px;
    object-fit: contain;
  }
}
