body.page-about-team-detail {
  padding-top: 200px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
  @media (--lt-800) {
    padding-top: 50px;
  }
}

#team-profile {
  & .container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 64px;
    position: relative;
    z-index: 2;
    padding-top: 32px;
    padding-bottom: 32px;

    @media (--lt-1200) {
      gap: 32px;
    }

    @media (--lt-800) {
      gap: 20px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  & .team-profile-image {
    grid-column: span 1 / span 1;
    & img {
      @media (--lt-800) {
        display: block;
        margin: 0 auto;
      }
    }
  }

  & .team-profile-info {
    grid-column: span 2 / span 2;
    display: flex;
    flex-direction: column;
    @media (--lt-800) {
      align-items: center;
      gap: 16px;
      grid-column: span 1 / span 1;
    }
  }

  & .team-profile-info-name {
    margin-bottom: 0;
  }

  & .team-profile-info-job {
    margin-bottom: 16px;
    @media (--lt-800) {
      text-align: center;
    }
  }

  & .team-profile-info-data {
    margin-top: auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;
    align-items: center;

    @media (--lt-1200) {
      gap: 16px;
    }
    @media (--lt-800) {
      gap: 16px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      justify-items: center;
    }
  }

  & .team-profile-info-data-qr {
    & img {
      width: 150px;
    }
  }

  & .team-profile-info-contact {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media (--lt-800) {
      gap: 16px;
    }
  }

  & .team-profile-info-contact-item {
    display: flex;
    gap: 16px;
    align-items: center;
    @media (--lt-800) {
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }
    & svg {
      width: 16px;
      height: 16px;
      fill: var(--color-black);
    }

    & a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  & .team-profile-info-download {
    justify-self: end;
    text-align: center;
    @media (--lt-800) {
      margin-top: 10px;
    }
  }

  & .team-profile-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: var(--color-gray-2);
    height: 100%;
    width: 33vw;
    @media (--lt-800) {
      width: 100%;
      height: 33vh;
    }
  }
}
