body.page-error {
  padding-top: 130px;

  @media (--lt-1400) {
    padding-top: 100px;
  }
}

#error-intro {
  text-align: center;
}

#error-intro > .container {
  display: flex;
  margin-top: 3%;
  justify-content: space-between;

  @media (--lt-1100) {
    flex-direction: column;
  }

  @media (--lt-400) {
    margin-top: 0%;
  }
}

.error-intro-button {
  margin-top: 4em;

  @media (--lt-480) {
    margin-top: 0;
  }
}

.container-text {
  text-align: left;
  /* margin-top: 11%; */
  margin-top: 180px;
  height: 366px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--lt-1400) {
    margin-top: 130px;
  }

  @media (--lt-1100) {
    margin: 0px auto;
    height: 300px;
  }

  @media (--lt-480) {
    margin: 0px auto;
    /* max-height: 260px; */
    height: auto;
  }
}

.error-intro-title {
  white-space: nowrap;

  @media (max-width: 330px) {
    white-space: normal;
  }
}

.error-intro-text {
  max-width: 480px;
  position: relative;

  @media (max-width: 550px) {
    white-space: normal;
  }
}

.btn-error {
  padding: 1em 4em;
  margin-top: 15px;
}

.error-image-hidden {
  display: none;

  @media (--lt-1100) {
    display: block;
    max-width: 550px;
    margin: auto;
    padding-left: 30px;
  }

  @media (max-width: 650px) {
    display: block;
    max-width: 550px;
    margin: auto;
    padding-left: 30px;
  }

  @media (max-width: 610px) {
    display: block;
    max-width: 550px;
    margin: auto;
    padding-left: 0px;
  }

  @media (max-width: 500px) {
    display: block;
    width: 120%;
    /* min-width: 390px; */
    margin: auto;
    padding-left: 0;
  }

  @media (max-width: 330px) {
    display: block;
    width: 250px;
    margin: auto;
    padding-left: 0;
    min-width: 90px;
    max-width: 250px;
  }
}

.error-image {
  width: 820px;
  min-width: 600px;
  flex-shrink: 0;
  margin-left: 15%;

  @media (--lt-1400) {
    max-width: 600px;
    margin-left: 5%;
  }

  @media (--lt-1100) {
    display: none;
  }
}
