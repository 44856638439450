.card-slider .swiper-container {
  padding: 100px 0;
  margin: -100px 0;
  width: 100%;
  contain: layout;
}

.card-slider-content {
  width: 100%;
}

.card-slider-header {
  width: 100%;
  margin-bottom: 40px;

  & h1 {
    margin: 0;
    order: 1;

    @media (--lt-1000) {
      margin-bottom: 0.5em;
    }
  }

  /* Common controls classes are in separate file common for all sliders in dev/front/css/plugins/swiper.css */
  & .slider-controls {
    margin-left: auto;
    padding-left: 2em;
    order: 2;
  }
}
